import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink as BootstrapNavLink } from 'reactstrap';
import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartBar, faAddressBook } from '@fortawesome/free-solid-svg-icons'
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./NavVerticalMenu.css"
library.add(faChartBar, faAddressBook )


const NavbarVerticalMenu = ({ routes, location, setShowBurgerMenu }) => {
  const [openedIndex, setOpenedIndex] = useState(null);
  useEffect(() => {
    let openedDropdown = null;
      routes.forEach((route, index) => {
          if (route['dropValues']) {
              return;
          }
     if (location.pathname.indexOf(route.to) === 0) openedDropdown = index;
    });

    setOpenedIndex(openedDropdown);
    // eslint-disable-next-line
  }, []);

    function ContextAwareToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );
        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <button
                type="button"
                style={{ backgroundColor: isCurrentEventKey ? 'pink' : 'lavender' }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>)
    }



  const toggleOpened = (e, index) => {
    e.preventDefault();
    return setOpenedIndex(openedIndex === index ? null : index);
  };

  const getHr = name => {
    if (name === 'Widgets' || name === 'Documentation') {
      return (
        <div className="navbar-vertical-divider">
          <hr className="navbar-vertical-hr my-2" />
        </div>
      );
    }
    };

 

    return routes.map((route, index) => {
        if (route['dropValues']) {
            return (
                <div style={{marginBottom:"0.5rem", marginTop:"0.5rem"} }>
                <Accordion>
                <Accordion.Item eventKey="0">
                        <Accordion.Header style={{ background: "transparent" }}>
                            <span className="nav-link-icon">
                                    <FontAwesomeIcon icon={route.icon} style={{ color:"#5e6e82"}} />
                        </span>
                            <span className="nav-link-text" style={{
                                fontSize: "0.9rem", color: "#5e6e82", whiteSpace: "nowrap"} }>{route.name}</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            <Accordion.Collapse eventKey="0">
                                <NavItem>
                                    {route['dropValues']?.map(value => {
                                        return (
                                            <NavLink className="nav-link" to={ value.to} onClick={() => setShowBurgerMenu(false)}>
                                                <NavbarVerticalMenuItem route={value} />
                                            </NavLink>
                                         )})}   
                                </NavItem>
                            </Accordion.Collapse>
                        </Accordion.Body>
                </Accordion.Item>
                    </Accordion>
                </div>
                    )
        }
      if (!route.children) {
      return (
          <Fragment key={index} >
          {getHr(route.name)}
          <NavItem>
            <NavLink className="nav-link" {...route} onClick={() => setShowBurgerMenu(false)}>
              <NavbarVerticalMenuItem route={route} />
            </NavLink>
          </NavItem>
        </Fragment>
      );
    }
    return (
      <Fragment key={index}>
        {getHr(route.name)}
        <NavItem>
          <BootstrapNavLink
            onClick={e => toggleOpened(e, index)}
            className="dropdown-indicator cursor-pointer"
            aria-expanded={openedIndex === index}
          >
            <NavbarVerticalMenuItem route={route} />
          </BootstrapNavLink>
          <Collapse isOpen={openedIndex === index}>
            <Nav>
              <NavbarVerticalMenu routes={route.children} location={location} />
            </Nav>
          </Collapse>
        </NavItem>
      </Fragment>
    );
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(NavbarVerticalMenu);
