import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import $ from 'jquery';
import '../login/Login.css';
import { generateErrorElementWithContactInfo, throwErrorIfNon200Response } from "../../helpers/utils";
import { toast } from "react-toastify";


export class ResetPassword extends Component {
    static displayName = ResetPassword.name;
    constructor(props) {
        document.title = "Advantix SmartSIM Portal - Reset Password";
        super(props);
        this.state = {
            resetpasswordform: '',
            confirmpassword: '',
            username:'',
            password: '',
            resetusername: '',
            resetpasswordstatus: '',
            exceptionmessage:'',
            strongpassword : new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})'),
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handlePassChange = this.handlePassChange.bind(this);
        this.handleConfirmPassChange = this.handleConfirmPassChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
    }
    componentDidMount() {
        fetch('/api/AuthenticateUser/ResetPasswordAuth', {
            headers: { 'Content-Type': 'application/json', },
            method: 'post',
            body: JSON.stringify({ resetpasswordtoken: this.props.location.search.replace("?","")})
        }).then(throwErrorIfNon200Response).then((response) => {
            return response.json().then((data) => {
                if (data.statusType === "Complete") {
                    this.setState({
                        resetpasswordform: 'show',
                        username: data.username,
                    });
                }
                if (data.errorMessage === "ResetTokenUsed") {
                    this.setState({
                        resetpasswordform: 'TokenUsed',
                        username: data.username,
                    });
                }
                if (data.errorMessage === "InvalidToken") {
                    this.setState({
                        resetpasswordform: 'InvalidToken',
                        username: data.username,
                    });
                }
                if (data.errorMessage === "ExpiredToken") {
                    this.setState({
                        resetpasswordform: 'TokenExpired',
                        username: data.username,
                    });
                }
                if (data.errorMessage === "Exception") {
                    this.setState({
                        resetpasswordform: 'Exception',
                        username: data.username,
                        exceptionmessage: data.errorMessage,
                    });
                }
            });
        }).catch(err => {
            toast.error("Unable to reset password. Please reach out to Advantix Support for assistance.");
            console.log(`error: ${err}`);
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        fetch('/api/AuthenticateUser/ResetPassword', {
            headers: { 'Content-Type': 'application/json', },
            method: 'post',
            body: JSON.stringify({ username: this.state.username, password: this.state.password })
        }).then(throwErrorIfNon200Response).then((response) => {
           return response.json().then((data) => {
                if (data.statusType === "Complete") {
                    this.setState({
                        resetpasswordstatus: "Complete",
                    });
                }
                else {
                    if (data.errorMessage === "DuplicateError") {
                        this.setState({
                            resetpasswordstatus: "Duplicate",
                        });
                    }
                    if (data.errorMessage === "Exception") {
                        this.setState({
                            resetpasswordstatus: "Exception",
                            exceptionmessage: data.errorMessage,
                        });
                    }
                }
            });
        }).catch((err) => {
            toast.error("Unable to reset password. Please reach out to Advantix Support for assistance.");
            console.log(`error: ${err}`);
        });
    }

    handleConfirmPassChange(evt) {
        this.setState({
            confirmpassword: evt.target.value,
        });
    }

    handlePassChange(evt) {
        this.setState({
            password: evt.target.value,
        });
    }

    validateForm() {
        return this.state.password.length >= 8 && this.state.strongpassword.test(this.state.password) && this.state.confirmpassword === this.state.password;
    }

    handleLogin() {
        $('.form').addClass('flip').delay(10).queue(() => {
            $('.form').removeClass('flip');
            $('.form').dequeue();
        });

        let path = `/`;
        this.props.history.push(path);
    }

    render() {
        return (
            <div className="Login">
                <form id="resetpassform" className="form" onSubmit={this.handleSubmit} style={{ display: this.state.resetpasswordform !== '' ? 'block' : 'none' }}>
                    <p align="center" block bsSize="large" style={{ color: 'red', display: this.state.resetpasswordform === 'Exception' ? 'block' : 'none' }}>{generateErrorElementWithContactInfo(this.state.exceptionmessage)}</p>
                    <p align="center" block bsSize="small" style={{ color: 'red', display: this.state.resetpasswordform === 'InvalidToken' ? 'block' : 'none' }}>Invalid Reset Password link. Please try resetting your password again</p>
                    <p align="center" block bsSize="small" style={{ color: 'red', display: this.state.resetpasswordform === 'TokenUsed' ? 'block' : 'none' }}>Reset Password link has already been used. Please try resetting your password again</p>
                    <p align="center" block bsSize="small" style={{ color: 'red', display: this.state.resetpasswordform === 'TokenExpired' ? 'block' : 'none' }}>Reset Password link has Expired. Please try resetting your password again</p>
                    <p align="center" block bsSize="small" style={{ color: 'red', display: (this.validateForm() === false && this.state.resetpasswordform ==='show') ? 'block' : 'none' }}>Password should be at least 8 characters long and should have atleast one uppercase, one number and one special symbol</p>
                    <p align="center" block bsSize="small" style={{ color: 'Green', display: this.state.resetpasswordstatus === 'Complete' ? 'block' : 'none' }}>Password has been reset. Please login using your new password</p>
                    <FormGroup controlId="password" bsSize="large" style={{ display: (this.state.resetpasswordstatus !== 'Complete' && this.state.resetpasswordform === 'show') ? 'block' : 'none' }}>
                        <FormLabel>Password</FormLabel>
                        <FormControl autoFocus type="password" value={this.state.password} onChange={this.handlePassChange} />
                    </FormGroup>
                    <FormGroup controlId="password" bsSize="large" style={{ display: (this.state.resetpasswordstatus !== 'Complete' && this.state.resetpasswordform === 'show') ? 'block' : 'none' }}>
                        <FormLabel>Confirm Password</FormLabel>
                        <FormControl value={this.state.confirmpassword} type="password" onChange={this.handleConfirmPassChange} />
                    </FormGroup>
                    <p align="center" block bsSize="small" style={{ color: 'red', display: this.state.resetpasswordstatus === 'Duplicate' ? 'block' : 'none' }}>You can't use last 12 Passwords</p>
                    <Button className="reset-password-submit" block bsSize="large" disabled={!this.validateForm()} color={this.validateForm() === true ? "#20AC07" : "#FFFFFF"} style={{ display: (this.state.resetpasswordstatus !== 'Complete' && this.state.resetpasswordform === 'show') ? 'block' :'none' }} type="submit">
                        Reset Password
                    </Button>
                    <br></br>
                    <p align="center" block><a href = "#"align="center" block bsSize="large" type="click" onClick={this.handleLogin}>Login Page</a></p>
                </form>
            </div>
        );
    }
}