import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, FormGroup, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { LoadingModal, createCase, formatStatusMessage } from '../ChangeServices/ChangeServiceCommon.js'
import { v4 as uuidv4 } from 'uuid';
 function SubmitSupportRequest() {
    const [accountOptions, setAccountOptions] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(localStorage.getItem("PortalUserAccountName"));
    const [showModal, setShowModal] = useState(false);
    const inputrefSIM = useRef(null);
    const inputrefIssueDesc = useRef(null);
    const defOption = new Option(selectedAccount, "1", true, true);
    const [isInvalid, setIsInvalid] = useState(false);

    const sfAccountId = localStorage.getItem('salesforceId');
    const history = useHistory();
    useEffect(() => {
        const options = new Option(selectedAccount, "1", true, true);
        const accOptions = [options]
        setAccountOptions(accOptions);
    }, [selectedAccount]);

    const handleAccountSelect = (option) => {
        setSelectedAccount(option.innerHTML);
    }
     const handleInputChange = () => {
         setIsInvalid(false);
     };

    const handleSubmit = async (e) => {
        const txtMessagearea = inputrefIssueDesc.current;
        const txtSIMarea = inputrefSIM.current;
        setShowModal(true);
        let enteredsims = "";
        const toastProps = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        }
        try {
            if (txtMessagearea.value === "") {
                setIsInvalid(true);
                throw new Error("Please enter a Description of Issue/Request")
            }
            let formattedSimnum = String(txtSIMarea.value).replace(/\r\n\t|\n|\r|\t|\s/gm, ",");
            const simEntity = { sfAccountId: "", simNumbers: [], requiredStatus: "" };
            simEntity.sfAccountId = sfAccountId;
            enteredsims = formattedSimnum.split(",");
            if (enteredsims.length > 1) {
                throw new Error("Only one SIM Number is allowed to Send Request at a time")
            }
            
        }
        catch (e) {
            setShowModal(false);
            const errorMessage = (e && e.message) || 'Please provide valid values';
            toast.error(errorMessage, toastProps);
            return false;
        }

        let messageEntity = {
            "threadId": uuidv4(),
            "createdDateTime": new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
            "transactionType": "",
            "payload": {
                "PTN__C": enteredsims[0].trim(),
                "Description": "For the benefit of requestor: " + localStorage.getItem('PortalUserFullname') + "\n" +
                    "Case Fields\n" +
                    "- Service Number Related to Request: " + enteredsims[0].trim() + "\n" +
                    "- Request Description: " + txtMessagearea.value,
                "AccountId": sfAccountId,
                "RecordTypeId": "012600000004v2BAAQ",
                "OwnerId": "00G5x000006pbcnEAA",
                "Type": "SmartSIM/Connectivity Services",
                "Status": "New",
                "Origin": "Web Request",
                "ContactId": localStorage.getItem('PortalUserFullname'),
                "ContactEmail": localStorage.getItem('PortalUserEmail'),
                "Subject": selectedAccount + " Equipment Issue " + enteredsims[0].trim(),
                "Active_Provider__c": "Advantix (Connectivity)"
            }
        }


            try {

                let orderResp = await createCase({ objCaseMessageEntity: messageEntity })

                if (Object.hasOwn(orderResp, 'payload') && orderResp["payload"]["statusType"] === "Complete") {
                    toast.success(formatStatusMessage({ objStatus: orderResp }), toastProps)
                    history.push("/home");
                }
                else {
                    toast.error(formatStatusMessage({ objStatus: orderResp }), toastProps);
                    history.push("/home");
                }
                setShowModal(false);
            }
            catch (e) {
                setShowModal(false);
                toast.error(formatStatusMessage({ objStatus: e }), toastProps);
                history.push("/home");

            }

        setShowModal(false);

    }


    return (
        <>
            <LoadingModal showModal={showModal} >


            </LoadingModal>
            <Row>
                <Col >
                    <h3>Submit Support Request</h3>
                    <hr></hr>
                    <Form>
                        <Row>
                            <Col lg={10} md={8}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Account</Form.Label>
                                    <div >
                                        <Select
                                            className="basic-single"
                                            defaultValue={defOption}
                                            isSearchable={false}
                                            isDisabled={true}
                                            onChange={(option) => handleAccountSelect(option)}
                                            name="Account"
                                            options={accountOptions}
                                        />
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={10} md={8} >
                                <FormGroup className="mb-3">
                                    <Form.Label>Device ID (SIM #/ICCID/IMEI/SN) (If Known)</Form.Label>
                                    <div>
                                        <Form.Control ref={inputrefSIM} type="text" style={{ width: '' }} />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={10} md={8}>
                                <Form.Group className="mb-3 lg-2" controlId="ControlTextarea1">
                                    <div style={{ marginTop: '15px' }} >
                                        <Form.Label >Description of Issue/Request</Form.Label>
                                        <Form.Control ref={inputrefIssueDesc} as="textarea" rows={3} onChange={handleInputChange}
                                            style={{ border: isInvalid ? '1px solid red' : '1px solid rgb(135, 163, 195)', width: '' }} />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={10} md={8}>
                                <FormGroup>
                                    <Button as="input" type="button" value="Submit Request" style={{ float: 'right' }} onClick={(e) => handleSubmit(e.target)} />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default SubmitSupportRequest;