import React, { useState } from 'react';
import { Card, CardBody, CardHeader, Col, Collapse, Row, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FieldRow } from "../common/FieldRow";
import moment from "moment";
import { saveAs } from 'file-saver';
import { toast } from "react-toastify";
import "./BillingAccordion.css";
import { generateErrorElementWithContactInfo, throwErrorIfNon200Response } from "../../helpers/utils";

export const BillingAccordion = ({ billing, open }) => {
    const [isOpen, setIsOpen] = useState(open);
    const [excelLoading, setExcelLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);

    const statementDateMoment = moment(billing.StatementDate);
    const usageStartDateMoment = moment(billing.UsageStartDate);
    const usageEndDateMoment = moment(billing.UsageEndDate);
    const rateBillingPlanStartDateMoment = moment(billing.RatePlanBillingStartDate);
    const rateBillingPlanEndDateMoment = moment(billing.RatePlanBillingEndDate);
    const monthName = statementDateMoment.format("MMM");
    const billingYear = statementDateMoment.format("yyyy");
    //const gbUsed = (billing.KbUsed / 1000000)?.toFixed(0);
    //const dataPool = (billing.Datapool / 1000000)?.toFixed(0);

    const excelExport = (event) => {
        if (typeof billing.BillingId === "number") {
            setExcelLoading(true);
            fetch('/api/BillingController/ExportBillExcel', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                },
                method: 'post',
                body: JSON.stringify({ billingId: billing.BillingId, billingMode: "Detail" })
            }).then(throwErrorIfNon200Response).then((response) => {
                setExcelLoading(false);
                return response.blob().then((blob) => {
                    var filename = 'BillingReport_' + billing.BillingId;
                    saveAs(blob, `${filename}.xlsx`);
                });
            }).catch((ex) => {
                setExcelLoading(false);
                toast.error(generateErrorElementWithContactInfo("Error exporting to excel."));
                console.error(`error: ${ex}`);
            });
        } else {
            console.error("Billing does not have BillingId");
        }
    };


    const pdfexport = (event) => {
        setPdfLoading(true);
        fetch('/api/BillingController/ExportBillPDF', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post',
            body: JSON.stringify({ billingId: billing.BillingId, billingMode: "Detail" }),
            responseType: 'arrayBuffer'
        }).then(throwErrorIfNon200Response).then((response) => {
            setPdfLoading(false);
            return response.arrayBuffer().then((pdf) => {
                var name = 'BillingStatement_' + billing.BillingId;
                var blob = new Blob([pdf], {
                    type: 'application/pdf'
                });
                var url = URL.createObjectURL(blob);
                var link = document.createElement('A');
                link.href = url;
                link.download = `${name}.pdf`;
                link.click();
            });
        }).catch((ex) => {
            setPdfLoading(false);
            toast.error(generateErrorElementWithContactInfo("Error exporting to pdf."));
            console.error(`error: ${ex}`);
        });
    };

    return (
        <Card className="border-bottom mb-3">
            <CardHeader onClick={() => setIsOpen(!isOpen)}
                        className="py-2 cursor-pointer d-flex align-items-center p-3 card-header-accordion">
                <div style={{ width: "5%", zIndex: 5 }}>
                    <FontAwesomeIcon icon="caret-right" transform={`rotate-${isOpen ? 90 : 0})`}/>
                </div>
                <h5 style={{ width: "14%" }}
                    className="font-weight-medium text-dark text-sans-serif pl-2">{monthName} {billingYear}</h5>
                {!isOpen ?
                    <>
                        <div style={{ width: "27%" }}
                             className="font-weight-medium text-dark text-sans-serif pl-2"><span
                            className="mr-1">Total</span> ${billing.TotalCurrentCharge?.toFixed(2)}</div>
                        <div style={{ width: "27%" }}
                             className="font-weight-medium text-dark text-sans-serif pl-2"><span
                            className="mr-1">MRC</span> ${billing.MRC}</div>
                        <div style={{ width: "27%" }}
                             className="font-weight-medium text-dark text-sans-serif pl-2"><span
                            className="mr-1">Usage</span> ${billing.UsageCharge?.toFixed(2)}</div>
                    </> :
                    <></>
                }
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody className="pt-2 accordion-card-body">
                    <div className="d-flex justify-content-end mt-n5 mb-3">
                        <div className="icon-box mr-2">
                            {
                                excelLoading ?
                                    <Spinner size="sm"/> :
                                    <FontAwesomeIcon className="clickable-icon" icon="file-excel" size="lg"
                                                     color="green"
                                                     onClick={excelExport}/>
                            }
                        </div>
                        <div className="icon-box mr-1">
                            {
                                pdfLoading ?
                                    <Spinner size="sm"/> :
                                    <FontAwesomeIcon className="clickable-icon" icon="file-pdf" size="lg" color="red"
                                                     onClick={pdfexport}/>
                            }
                        </div>
                    </div>
                    <Row>
                        <Col md={6} sm={12}>
                            <FieldRow title="Account Number">{billing.Accountnumber}</FieldRow>
                            <FieldRow title="Account">
                                <div>
                                    {billing.Accountname}
                                </div>
                                <div>
                                    {billing.Address1}
                                </div>
                                <div>
                                    {billing.Address2}
                                </div>
                                <div>
                                    {billing.City}, {billing.Zip}
                                </div>
                            </FieldRow>
                        </Col>
                        <Col md={6} sm={12}>
                            <FieldRow title="Statement Date">{statementDateMoment?.format("MM/DD/YY")}</FieldRow>
                            <FieldRow title="Statement Number">{billing.StatementNumber}</FieldRow>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="mb-3 mt-4">
                        <Col md={4}>
                            <FieldColumn title="Subscribers" value={billing.Subscribers}/>
                        </Col>
                        <Col md={4}>
                            <FieldColumn title="MRC" value={`$${billing.MRC?.toFixed(2)}`}/>
                        </Col>
                        <Col md={4}>
                            <FieldColumn
                                title="Rate Plan Billing Period"
                                value={`${rateBillingPlanStartDateMoment?.format("MM/DD/YY")} - ${rateBillingPlanEndDateMoment?.format("MM/DD/YY")}`}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={4}>
                            <FieldColumn
                                title="GB Used / Pool"
                                value={billing.PoolPlanGroup.map(x => <div>{x.PoolGroup == "Not Applicable" ? "" : x.PoolGroup} {x.PoolGroup == "Not Applicable" ? "" : " : "}{(x.KbUsed / 1000000)?.toFixed(0)}GB / {(x.Datapool / 1000000)?.toFixed(0)}GB</div>)}
                                
                            />
                        </Col>
                        <Col md={4}>
                            <FieldColumn
                                title="Usage Charge"
                                value={`$${billing.UsageCharge?.toFixed(2)}`}
                            />
                        </Col>
                        <Col md={4}>
                            <FieldColumn
                                title="Usage Billing Period"
                                value={`${usageStartDateMoment?.format("MM/DD/YY")} - ${usageEndDateMoment?.format("MM/DD/YY")}`}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md={4}>
                            <FieldColumn
                                title="Other Charges"
                                value={`$${billing.OtherCharges?.toFixed(2)}`}
                            />
                        </Col>
                        <Col md={4}>
                            <FieldColumn
                                title="One Time Charges"
                                value={`$${billing.OneTimeCharges?.toFixed(2)}`}
                            />
                        </Col>
                        <Col md={4}>
                            <FieldColumn
                                title="Total Current Charges"
                                value={`$${billing.TotalCurrentCharge?.toFixed(2)}`}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    );
}

const FieldColumn = ({ title, value }) => (
    <Row className="mb-1">
        <Col xs={6} sm={6} md={12}>
            <div className="font-weight-semi-bold">{title}</div>
        </Col>
        <Col xs={6} sm={6} md={12}>
            <div>{value}</div>
        </Col>
    </Row>
);

BillingAccordion.defaultProps = { open: false };