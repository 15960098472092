import classNames from 'classnames';
import is from 'is_js';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Collapse, Nav, Navbar } from 'reactstrap';
import bgNavbarImg from '../../assets/img/generic/bg-navbar.png';
import { navbarBreakPoint } from '../../config';
import Flex from '../falcon/common/Flex';
import Logo from '../falcon/navbar/Logo';
import NavbarVerticalMenu from '../falcon/navbar/NavbarVerticalMenu';
import ToggleButton from '../falcon/navbar/ToggleButton';
import { routes } from "../../routes";


const NavbarVertical = ({navbarStyle, showBurgerMenu, setShowBurgerMenu}) => {
    const navBarRef = useRef(null);
    const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(true);

    const HTMLClassList = document.getElementsByTagName('html')[0].classList;
    //Control Component did mount and unmounted of hover effect
    if (isNavbarVerticalCollapsed) {
        HTMLClassList.add('navbar-vertical-collapsed');
    }

    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add('windows');
        }
        if (is.chrome()) {
            HTMLClassList.add('chrome');
        }
        if (is.firefox()) {
            HTMLClassList.add('firefox');
        }
        return () => {
            HTMLClassList.remove('navbar-vertical-collapsed-hover');
        };
    }, [isNavbarVerticalCollapsed, HTMLClassList]);

    //Control mouseEnter event
    let time = null;
    const handleMouseEnter = () => {
        if (isNavbarVerticalCollapsed) {
            time = setTimeout(() => {
                HTMLClassList.add('navbar-vertical-collapsed-hover');
            }, 100);
        }
    };

    return (
        <Navbar
            expand={navbarBreakPoint}
            className={classNames('navbar-vertical navbar-glass', {
                [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
            })}
            light
        >
            <Flex align="center">
                <ToggleButton
                    isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
                    setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
                />
                <Logo at="navbar-vertical" width={40}/>
            </Flex>

            <Collapse
                navbar
                isOpen={showBurgerMenu}
                className="scrollbar"
                innerRef={navBarRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={() => {
                    clearTimeout(time);
                    HTMLClassList.remove('navbar-vertical-collapsed-hover');
                }}
                style={
                    navbarStyle === 'vibrant' && {
                        backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbarImg})`
                    }
                }
            >
                <Nav navbar vertical>
                    <NavbarVerticalMenu setShowBurgerMenu={setShowBurgerMenu} routes={routes}/>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

NavbarVertical.protoTypes = {
    navbarStyle: PropTypes.string
};

NavbarVertical.defaultProps = {
    navbarStyle: ''
};

export default NavbarVertical;
