import React, { useState, useEffect } from 'react';
import NavbarTop from "./common/NavbarTop";
import NavbarVertical from "./common/NavbarVertical";
import SidePanelModal from "./common/SidePanelModal";
import { AuthRoute } from "./common/AuthRoute";
import { ResetPassword } from "./reset-password/ResetPassword";
import { Home } from "./home/Home";
import { Billing } from "./billing/Billing";
import { Inventory } from "./inventory/Inventory";
import LiveData from "./inventory/LiveData"
import InventoryData from "./inventory/InventoryData"
import Usage from "./Usage/Usage"
import './Layout.css';
import { toast } from "react-toastify";
import { generateErrorElementWithContactInfo, throwErrorIfNon200Response } from "../helpers/utils";
import AssestProfile from './inventory/AssestProfile';
import OrderNewSim from "./OrderActivation/OrderNewSimjs"
import PendingRequestEndCustomer from "./OrderActivation/PendingRequestEndCustomer"
import PendingRequest from "./OrderActivation/PendingRequest"
import ActivateOrder from "./OrderActivation/AcitvationOrder"
import ManageGroupValues from "./ManageReportGroups/ManageGroupValues"
import ChangePlan from "./ChangeServices/ChangePlan"
import ChangeCarrier from './ChangeServices/ChangeCarrier';
import SuspendOrCancel from './ChangeServices/SuspendOrCancel';
import ReactivateSuspendedSIM from './ChangeServices/ReactivateSIM';
import SendMessage from './Troubleshooting/SendMessageToDevice';
import ViewMessageLogs from './Troubleshooting/ViewSMSLogs';
import SubmitSupportRequest from './Troubleshooting/SubmitSupportRequest';
import PendingActivationRequest from './OrderActivation/PendingActivationRequest';
import AccountAndUserManagement from './UserManagement/AccountAndUserManagement';

//TODO: DROP DOWN VALUE
export const LayoutWithContent = () => {
    const [showBurgerMenu, setShowBurgerMenu] = useState(false);
    const [sideModalOpen, setSideModalOpen] = useState(false);
    const [usageData, setUsageData] = useState(null);
    const [usagePoolGroupData, setUsagePoolGroupData] = useState([]);
    const [usageDataLoading, setUsageDataLoading] = useState(false);

    useEffect(() => {
        setUsageDataLoading(true);
        fetch('/api/SmartSIMUsageController/GetUsageData', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post'
        }).then(throwErrorIfNon200Response).then((response) => {
            setUsageDataLoading(false);
            return response.json().then((data) => {
                if (data.AccountId > 0) {
                    console.log("data")
                    setUsageData(data);
                    setUsagePoolGroupData(data.PoolPlanGroup);
                    // CountUp: this.state.usageJson.CurrentBill
                } else {
                    throw new Error("Error retrieving usage data.");
                }
            });
        }).catch((ex) => {
            setUsageDataLoading(false);
            toast.error(generateErrorElementWithContactInfo("Error retrieving usage data."));
            console.log(`error: ${ex}`);
        });
    }, []);
  
    return (
        <div className='container'>
            <NavbarVertical
                setShowBurgerMenu={setShowBurgerMenu}
                showBurgerMenu={showBurgerMenu}
                isKanban={false}
                navbarStyle="transparent"
            />
            <div className="content">
                <NavbarTop
                    setShowBurgerMenu={setShowBurgerMenu}
                    showBurgerMenu={showBurgerMenu}
                    setSideModalOpen={setSideModalOpen}
                />
                <div className="p-3 mt-4">
                    <AuthRoute path='/change-password' component={ResetPassword} />
                    <AuthRoute path='/home' component={Home} componentProps={{ usageData, usageDataLoading, usagePoolGroupData }} />
                    <AuthRoute path='/billing' component={Billing}/>
                    <AuthRoute path='/inventory' component={Inventory} componentProps={{ usageData }} />
                    <AuthRoute path='/usage' component={Usage} componentProps={{ usageData } } />
                    <AuthRoute path='/inventorydetails-live-data/:id' component={LiveData} />
                    <AuthRoute path='/inventorydetails-inventory/:id' component={InventoryData} />
                    <AuthRoute path='/inventorydetails-edit-inventory/:id' component={AssestProfile} />
                    <AuthRoute path='/orderSim' component={OrderNewSim} componentProps={{ usageData }} />
                    <AuthRoute path='/pendingrequest' component={PendingRequestEndCustomer} componentProps={{ usageData }} />
                    <AuthRoute path='/request' componentProps={{ usageData }} component={PendingRequest} />
                    <AuthRoute path='/activate' component={ActivateOrder} componentProps={{ usageData }} />
                    <AuthRoute path='/managereports' componentProps={{ usageData }} component={ManageGroupValues} />
                    <AuthRoute path='/ChangePlan' componentProps={{ usageData }} component={ChangePlan} />
                    <AuthRoute path='/ChangePlan/:sim' componentProps={{ usageData }} component={ChangePlan} />
                    <AuthRoute path='/changecarrier' componentProps={{ usageData }} component={ChangeCarrier} />
                    <AuthRoute path='/changecarrier/:sim' componentProps={{ usageData }} component={ChangeCarrier} />
                    <AuthRoute path='/SuspendOrCancel' component={SuspendOrCancel}></AuthRoute>
                    <AuthRoute path='/SendMessageToDevice' component={SendMessage}></AuthRoute>
                    <AuthRoute path='/ViewSMSLogs' component={ViewMessageLogs}></AuthRoute>
                    <AuthRoute path='/AccountAndUserManagement' component={AccountAndUserManagement}></AuthRoute>
                    <AuthRoute path='/SubmitSupportRequest' component={SubmitSupportRequest}></AuthRoute>
                    <AuthRoute path='/ReactivateSIM' componentProps={{ usageData }} component={ReactivateSuspendedSIM}></AuthRoute>
                    <AuthRoute path='/PendingActivationRequest' componentProps={{ usageData }} component={PendingActivationRequest} />
                </div>
            </div>
            <SidePanelModal sideModalOpen={sideModalOpen} setSideModalOpen={setSideModalOpen} usageData={usageData} usagePoolGroupData={usagePoolGroupData}/>
        </div>
    );
}
