import React from 'react';
import FalconCardHeader from '../falcon/common/FalconCardHeader';
import { Card, CardBody, Col, Row, Spinner } from 'reactstrap';
import Flex from '../falcon/common/Flex';
import { getGrays, themeColors, rgbaColor, getPosition, numberFormatter } from '../../helpers/utils';
import { Circle } from 'react-es6-progressbar.js';
import './Usage.css';


const Usage = ({title, usagePercentage, dataPoolMB, projectedUsage, usageMonthToDate, loading}) => {
    let circleColor = themeColors.primary;
    if (usageMonthToDate > dataPoolMB) {
        circleColor = themeColors.danger;
    } else if (projectedUsage > dataPoolMB) {
        circleColor = themeColors.warning;
    }

    const options = {
        color: circleColor,
        strokeWidth: 5,
        trailWidth: 5,
        trailColor: rgbaColor('#000', 0.15),
        easing: 'easeInOut',
        duration: 3000,
        svgStyle: {
            'stroke-linecap': 'round',
            display: 'block',
            width: '100%'
        },
        text: {autoStyleContainer: false},
        // Set default step function for all animate calls
        step: (state, circle) => {
            circle.setText(`<span class='value'>${usagePercentage}<b>%</b></span>`);
        }
    };

    return (
        <Card className="graph-card mb-3">
            <FalconCardHeader className="pb-0" title={title} light={false} titleTag="h6" titleClass="text-center"/>
            <CardBody tag={Flex} align="end">
                <Row className="m-auto flex-grow-1  h-100">
                    <Col className="m-auto d-flex flex-column">
                        {
                            !loading ?
                                <>
                                    {
                                        typeof usagePercentage === "number" &&
                                        <Circle
                                            progress={usagePercentage > 100 ? 1 : usagePercentage / 100}
                                            options={options}
                                            container_class="progress-circle progress-circle-dashboard m-auto"
                                            container_style={{width: '150px', height: '150px'}}
                                        />
                                    }
                                    {typeof projectedUsage === "number" &&
                                    <p className="fs--1 text-center mt-4">{`Projected Usage for this month is ${projectedUsage} GB`}</p>}
                                </> :
                                <Spinner className="m-auto" type="grow"/>
                        }

                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default Usage;
