import React  from 'react';
import { Navbar, Nav } from 'reactstrap';
import Logo from '../falcon/navbar/Logo';
import TopNavRightSideNavItem from '../falcon/navbar/TopNavRightSideNavItem';
import SearchBox from "../falcon/navbar/SearchBox";
import { NavItem } from "react-bootstrap";
import { navbarBreakPoint, topNavbarBreakpoint } from "../../config";

const NavbarTop = ({ setShowBurgerMenu, showBurgerMenu, setSideModalOpen }) => {

    const handleBurgerMenu = () => {
        setShowBurgerMenu(!showBurgerMenu);
    };

    return (
        <Navbar
            light
            className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit"
            expand={false}
        >
            <div className={`toggle-icon-wrapper mr-md-3 mr-2 d-${navbarBreakPoint}-none`}>
                <button
                    className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
                    onClick={handleBurgerMenu}
                    id="burgerMenu"
                >
                  <span className="navbar-toggle-icon">
                    <span className="toggle-line"/>
                  </span>
                </button>
            </div>
            <div className='d-flex'>
                <Logo at="navbar-top" width={40} id="topLogo" />
                <TopNavRightSideNavItem setSideModalOpen={setSideModalOpen} />
                </div>
            <Nav navbar className={`align-items-center d-none d-${topNavbarBreakpoint}-block`} >
                <NavItem>
                    <SearchBox />
                </NavItem>
            </Nav>

           
        </Navbar>
    );
};

export default NavbarTop;
