import React from "react";
import { Redirect, Route } from "react-router-dom";
import jwt_decode from "jwt-decode";


export const checkAuth = () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        return false;
    }
    try {
        console.log(token);
        const {exp} = jwt_decode(token);
        console.log("exp=" + exp);

        if (exp < new Date().getTime() / 1000) {
            return false;
        }

    } catch (e) {
        return false;
    }

    return true;
};

export const AuthRoute = ({component: Component, componentProps, ...rest}) => {
    if (componentProps === undefined || componentProps === null) componentProps = {};

    return (
        <Route {...rest} render={props => (
            checkAuth() ? (
                <Component {...props} {...componentProps} />
            ) : (
                <Redirect to={{pathname: '/'}}/>
            )
        )}/>
    );

};
