
import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Row, Col, Button, FormControl, FormGroup, } from 'react-bootstrap';
import { Card, CardBody, Spinner } from 'reactstrap';
import GroupCellRender from "./GroupCellRender";
import DetailsCellRender from "./DetailsCellRender.js";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify'



class ManageGroupValues extends Component {
    constructor(props) {
        super(props);
        this.manageReport = React.createRef();
        //const [details, setDetails] = useState(false);
    }

    state = {
        isAddingNewGroup: false,
        GroupName: "",
        GroupValue:"",
        data: null,
        formateData: null,
        selectedGroupName:"",
        transformData: [],
        error: false,
        selectedGroupName:"",
        GroupFormatMap: new Map(),
        errorMessage:null
    }


    fecthGroupValues = () => {
        fetch("api/AccountManagementController/GetGroupInfo", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "post",
            body: JSON.stringify({
                "sfAccountId": localStorage.getItem('salesforceId'),

            })
        }).then(response => {
            return response.json().then(data => {
                this.setState({ data: data }, () => {
                    if (data['statusType'] === 'Error') {
                        this.setState({ errorMessage: data['errorMessage'] })
                        return;
                    }
                    this.TransformReportGroupsData(data)
                })


            })

        })
    }

    TransformReportGroupsData = (data) => {
        const mapFormat = new Map()
        data.forEach(group => {
            let GroupValues = []
            if (mapFormat.get(group['GroupName'])) {
                GroupValues.push(group['GroupValue'])
                const currentData = mapFormat.get(group['GroupName'])
                const currentArray = [...currentData['Details'], ...GroupValues ]
                mapFormat.set(group['GroupName'], { NumberValues: currentArray.length, Details: currentArray })
          

            }
            else {

                GroupValues = []
                GroupValues.push(group['GroupValue'])
                mapFormat.set(group['GroupName'], { NumberValues: 1, Details: GroupValues })

            }
        })
     
        let newObject = []
        let reportObject = {}
        for (let [key, value] of mapFormat.entries()) {
            reportObject = {
                "GroupName":key,
                "NumberofValues": value.NumberValues,
                "Details": value.Details , }
            newObject.push(reportObject)
        }
        this.setState({ transformData: newObject, GroupFormatMap: mapFormat }, () => {
        })
    }
    addGrroupName = () => {
        if (this.state.GroupName.length > 0 && this.state.GroupValue.length > 0) {
            this.setState({ error: false }, () => {
                this.openCreateAddNewGroupModal()
            })
             
            fetch("api/AccountManagementController/AddGroupValues", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                },
                method: "post",
                body: JSON.stringify({
                    "salesForceId": localStorage.getItem('salesforceId'),
                    "GroupName": this.state.GroupName,
                    "GroupValue": [
                        this.state.GroupValue]
                })
            }).then(response => {
                return response.json().then(data => {
                    this.setState({
                        GroupName: "",
                        GroupValue: "",
                        selectedGroupName: "",})
                    this.fecthGroupValues()

                })
            })
        }
        else {
            toast.error("Please enter a Group Name and a Group Value.")
            this.setState({error:true})
        }
    }

    addGroupValue = (value, selectedGroupName) => {
        this.setState({ selectedGroupName: selectedGroupName })
        if (selectedGroupName.length > 0) {
            const currentGroupData = this.state.GroupFormatMap.get(value)
            fetch("api/AccountManagementController/AddGroupValues", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                },
                method: "post",
                body: JSON.stringify({
                    "salesForceId": localStorage.getItem('salesforceId'),
                    "GroupName": value,
                    "GroupValue": [selectedGroupName]
                })
            }).then(response => {
                return response.json().then(data => {
                    this.fecthGroupValues()

                })

            })
        }
        else {
            toast.error("Please enter a Group Value.")
            return;

        }

    }

    setSelctedGroupName = (groupName, value) => {
        this.setState({ selectedGroupName: groupName }, () => {
            this.addGroupValue(value,this.state.selectedGroupName)})

    }

    componentDidMount = () => {
        this.fecthGroupValues()
    }

    columnDefs = [
        {
            headerName: 'Group Name',
            headerTooltip: "Group Name",
            field: 'GroupName',
            tooltipField: "Group Name",
            minWidth: 200,
            cellRenderer: "GroupCellRender"

        },

        {
            headerName: 'Number of Values',
            headerTooltip: "Number of Values",
            field: 'NumberofValues',
            minWidth: 170,
            cellRenderer: "GroupCellRender"

        },
        {
            headerName: 'Details',
            headerTooltip: 'Details',
            field: 'Details',
            minWidth: 100,
            cellRenderer: "DetailsCellRender",
            cellRendererParams: {
                addGroupValue: () => {  },
                setSelctedGroupName: this.setSelctedGroupName,
                isValid: this.state.selectedGroupName?.length > 0
            }
        },
    ];

    defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
    };
    onGridReady(params) {
        window.addEventListener('resize', () => {
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            });
        });
        params.api.sizeColumnsToFit();
    };
    openCreateAddNewGroupModal = () => {
        this.setState({ isAddingNewGroup: !this.state.isAddingNewGroup }, () => {
            
        })

    }

  

    render() {
        return (
            <>
                <Modal size="lg" centered show={this.state.isAddingNewGroup} onHide={this.openCreateAddNewGroupModal}>
                    <Modal.Header closeButton onClick={() => {}}>
                        <Modal.Title>Add New Group</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{}}>
                        <Row className="mt-3 " style={{ alignItems: "center" }} >
                            <Col sm={12} md={3} lg={12} xl={12} className="field-root"    >
                                <h2 style={{ fontSize: "1rem" }} className="live-data-title">Group Name:</h2>
                            </Col>
                            <Col sm={12} md={12} lg={12} xl={12} className=" manage-field-root" >
                                <FormGroup controlId="GroupName" >
                                    <FormControl style={{ border: this.state.error && this.state.GroupName.length <= 0 ? '1px solid red' : '1px solid rgb(135, 163, 195)' }}
                                        onChange={(event) => {
                                        this.setState({ GroupName: event.target.value }, () => {
                                        })
                                    }} autoComplete="GroupName" className="input-manage-field" autoFocus type="text" />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={12} lg={12} xl={12} style={{marginTop:"2rem"}} className="field-root"    >
                                <h2 style={{ fontSize: "1rem" }} className="live-data-title">Group Value:</h2>
                            </Col>
                            <Col sm={12} md={12} lg={12} xl={12} className=" manage-field-root" >
                                <FormGroup controlId="GroupValue" >
                                    <FormControl style={{ border: this.state.error && this.state.GroupValue.length <= 0 ? '1px solid red' : '1px solid rgb(135, 163, 195)' }}
                                        onChange={(event) => {
                                        this.setState({ GroupValue: event.target.value }, () => {
                                        })
                                    }} autoComplete="GroupValue" className=" input-manage-field" type="text" />
                                </FormGroup>
                            </Col>

                            <Col style={{ marginTop: "2rem" }} sm={12} md={2} lg={2} xl={2}
                                className="group-button">
                                <Button onClick={this.addGrroupName} className="p-2" style={{ width: "100%" }}>
                                    <span>Add </span></Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
                <Row>
                    <Col sm={12} lg={12} md={12} style={{ marginBottom: "-5%" }}>
                        <Button onClick={this.openCreateAddNewGroupModal} className="p-2"><span>Add New Group</span></Button>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div className="ag-theme-alpine grid">
                                    <AgGridReact
                                        ref={this.manageReport}
                                        rowHeight={45}
                                        onGridReady={this.onGridReady}
                                        columnDefs={this.columnDefs}
                                        defaultColDef={this.defaultColDef}
                                        rowData={this.state.transformData}
                                        frameworkComponents={{
                                            customLoadingOverlay: () => <Spinner type="grow" />,
                                            customNoRowsOverlay: () => <div>No Group Data</div>,
                                            GroupCellRender: GroupCellRender,
                                            DetailsCellRender: DetailsCellRender
                                        }}
                                        loadingOverlayComponent='customLoadingOverlay'
                                        noRowsOverlayComponent='customNoRowsOverlay'
                                        pagination
                                        paginationAutoPageSize
                                        enableCellTextSelection
                                        tooltipShowDelay={0}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </>
        )
    }

}

export default ManageGroupValues;

                        