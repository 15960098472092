import React, { useState, useEffect } from 'react';
import InventoryTable from "./InventoryTable";
import { toast } from "react-toastify";
import { generateErrorElementWithContactInfo, throwErrorIfNon200Response } from "../../helpers/utils";

const Inventory = (props) => {
    document.title = "Advantix SmartSIM Portal - Inventory";

    const [formStatus, setFormStatus] = useState('');
    const [count, setCount] = useState('');
    const [inventoryJson, setInventoryJson] = useState(null);

    useEffect(() => {
        fetch('/api/SmartSIMInventoryController/GetInventoryData', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post'
        })
            .then(throwErrorIfNon200Response)
            .then((response) => {
                return response.json().then((data) => {
                    if (Array.isArray(data)) {
                        if (data.length > 0) {
                            setFormStatus("Complete");
                            setCount(data.length);
                            setInventoryJson(data);
                        }
                    } else {
                        throw new Error("Error retrieving inventory details.");
                    }
                });
            })
            .catch((ex) => {
                setInventoryJson([]);
                toast.error(generateErrorElementWithContactInfo("Error retrieving inventory details."));
            });
    }, []); // Empty dependency array ensures this runs only on mount.

    return (
        <>
            <InventoryTable data={inventoryJson} setIsSelected={false} usageData={props.usageData} />
        </>
    );
};

export { Inventory };
