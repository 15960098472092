import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import advantixHorizontalLogo from "../../../assets/img/illustrations/Grooov_Pulse_logo.png"
import "./Logo.css";

const Logo = ({at, width, className, ...rest}) => {
    return (
        <Link
            to="/home"
            className={classNames(
                'text-decoration-none',
                { 'navbar-brand text-left': at === 'navbar-vertical' },
                { 'navbar-brand text-left': at === 'navbar-top' }
            )}
            {...rest}
        >
            <div
                className={classNames(
                    'd-flex',
                    'align-items-center',
                    className
                )}
            >
                <img className="logo" src={advantixHorizontalLogo} alt="Advantix Logo"/>
            </div>
        </Link>
    );
};

Logo.propTypes = {
    width: PropTypes.number,
    className: PropTypes.string
};

export default Logo;
