import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import FalconDatePicker from "./DatePicker"
import "./Usage.css"



const ReportDate = ({ handleStartDateSelection, reportstartDate, handleEndDateSelection, reportEndDate }, props) => {
    return (
        <Col lg={7} md={12} xl={7} xxl={5} xs={12} style={{ display: 'flex', flexDirection: "" }} className="mt-4">
            <div style={{ marginBottom: '11px', marginRight: '4rem' }} className="date-container">
                <FontAwesomeIcon icon={faCalendarAlt} fontSize='5x' style={{ fontSize: '1.2rem', marginRight: '0.5rem', lineHeight: '30px' }} className='' />
                <span className="fw-bold" >Report Start Date</span>

                <FalconDatePicker handleStartDateSelection={handleStartDateSelection} start={true} ReportstartDate={reportstartDate} />
            </div>
            <div style={{ marginBottom: '0.5rem' }} className="date-container">
                <FontAwesomeIcon icon={faCalendarAlt} fontSize='5x' style={{ fontSize: '1.2rem', marginRight: '0.5rem', lineHeight: '30px' }} className='' />
                <span className="fw-bold" >Report End Date</span>
                <FalconDatePicker handleEndDateSelection={handleEndDateSelection} start={false} ReportEndDate={reportEndDate} />
            </div>
        </Col>


    )

}



export default ReportDate;