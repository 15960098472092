import React from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Row, Col } from 'react-bootstrap';

const DisplayOption = ({ children, isOptionOpen, closeOption, reportEndDate, reportStartDate, handleFilter }) => {


    return (
        <>
            <Col lg={7} md={12} xl={7} xxl={5} xs={12} >
            <Offcanvas show={isOptionOpen} onHide={() => { closeOption() }} placement="top">   
                <Offcanvas.Body style={{ padding: "2rem", lineHeight: '30px' }}>
                    {children}
                   
                    <div className="d-flex"  >
                        <div></div>
                        <div className="d-flex align-items-center" style={{ marginRight: '50px' }}>
                            <Button disabled={reportStartDate.getTime() >= reportEndDate.getTime()} onClick={handleFilter}
                                className="mr-3 buttons-root-container usage-filter-button" style={{ fontSize: "1rem" }}>
                                Apply Filter
                            </Button>
                            <Button onClick={closeOption} className="buttons-root-container"
                                style={{
                                    backgroundColor: "white",
                                    padding: '0.5rem 1rem',
                                    color: 'black',
                                    width: "8rem",
                                    fontSize: "1rem"
                                }}>
                                Cancel
                            </Button>
                        </div>
                        </div>
                </Offcanvas.Body>
            </Offcanvas>
        </Col >
        </>
    );
}

export default DisplayOption

