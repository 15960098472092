import { Link } from "react-router-dom"


const OrderDetaulsCellRender = (data) => {
    return <Link style={{ paddingLeft: '1rem' }} to={`/request/${data.value.orderId}/${data.value.InternalOrder}`}>{data.value.text}</Link>


}


export default OrderDetaulsCellRender