import React, { Component}  from 'react';
import { Route, Switch } from 'react-router';
import { LayoutWithContent } from './components/LayoutWithContent';
import { Login } from "./components/login/Login";
import { AuthRoute } from "./components/common/AuthRoute";
import { ResetPassword } from "./components/reset-password/ResetPassword";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from "./components/context/UserContext"

export default class App extends Component {

    setUser = (user) => {
        this.setState({user:user})
    }
    state = {
        user:null,
        setUser: this.setUser
    }

    render() {
        return (
            <>
                <ToastContainer position="top-right" />
                <UserContext.Provider value={ this.state}>
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route path="/reset-password" component={ResetPassword} />
                    <AuthRoute path="*">
                        <LayoutWithContent />
                    </AuthRoute>
                </Switch>
            </UserContext.Provider>
            </>
        );
    }
}
