import React, { Component, useState, useRef, useEffect } from 'react';

import { Row, Col, Button, FormGroup, } from 'react-bootstrap';
import './ChangeServices.css'

import Form from 'react-bootstrap/Form';
import Select from 'react-select';
/*import PropTypes, { string, any } from 'prop-types';*/
import { ValidateSIM, createOrder, LoadingModal, formatStatusMessage } from './ChangeServiceCommon'
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
class SuspendOrCancel extends Component {
    constructor(props) {
        super(props);
        document.title = "Suspend/Carrier"
    }
    
    render() {

        return (

            <SuspendOrCancelContent usageData={this.props.usageData}></SuspendOrCancelContent >
        );
    }
}

function SuspendOrCancelContent({ children, usageData }) {   
    const [suspend, setSuspend] = useState(true)
    const [cancel, setCancel] = useState(false)
    const [selectedAccount, setSelectedAccount] = useState("Select...")
    const [option, setOption] = useState("Suspend SIM");
    const inputRef = useRef(null);
    const masterAccountId = localStorage.getItem('masterAccountId');
    const sfAccountId = localStorage.getItem('salesforceId');
    const defOption = new Option(localStorage.getItem("PortalUserAccountName"), "1", true, true);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        let currURL = new URL(window.location);
        if (currURL.searchParams.has("sim")) {
            let txtarea = inputRef.current
            txtarea.value = currURL.searchParams.get("sim");
        }

    },[])
        
    let options = [defOption]
    const type = 'radio'
   
    const handleRadioCheck = (e, suspend, cancel) => {
        console.dir(e);
        
        setSuspend(suspend)
        setCancel(cancel)
        if (suspend)
            setOption("Suspend SIM");

        if (cancel)
            setOption("Cancel SIM");         
    }
   
    const handleAccountSelect = (option) => {
        setSelectedAccount(option.innerHTML);
    }
    
    const handleSubmit = async (e) => {        
        const txtarea = inputRef.current;
        setShowModal(true);
        let invalidsims = "";
        let orderType = option === "Suspend SIM" ? "Suspend" : "Cancellation";
        const toastProps = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        }
       
        let resp = {};
        try {
            if (txtarea.value === "") {
                throw new Error("Please enter SIM Number(s) before submitting an order.");
            }
            let formattedSimnum = String(txtarea.value).replace(/\r\n\t|\n|\r|\t|\s/gm, ",");
            let simEntity = { sfAccountId: "", simNumbers: [], requiredStatus: "" };
            simEntity.sfAccountId = localStorage.getItem('salesforceId');
            const enteredsims = formattedSimnum.split(",");
            enteredsims.forEach((value, index, arr) => {
                if (value !== "")
                    simEntity.simNumbers.push({ SIM: value.trim(), IMEI: '', PlanSKU: '', Status: '' });
            })
            simEntity.requiredStatus = "Active";           
            resp = await ValidateSIM({ simEntity: simEntity, requestType: orderType });
            if (Object.hasOwn(resp, 'response') && Object.hasOwn(resp["response"], 'invalidsims')) {
                invalidsims = resp["response"]["invalidsims"];
            }            
            if (invalidsims !== "") {
                let invalidSimerr = new Error(invalidsims);
                invalidSimerr.name = "Invalid SIM(s) "
                throw invalidSimerr;
            }
        }
        catch (e) {            
            toast.error(formatStatusMessage({ objStatus: e }), toastProps);
            setShowModal(false);
            history.push("/home");
            return false;
        }
      
        if (resp["status"] === "success" &&
            (Object.hasOwn(resp, 'response') && Object.hasOwn(resp["response"], 'validsims') &&
            resp["response"]["validsims"] !== ""))
        {

            let payload = {
                "orderId": "",
                "orderNumber": "",
                "internalOrderId": null,
                "accountSetupFee": null,
                "replacementFee": null,
                "overageRatePlanCost": null,
                "suspendPlanCost": null,
                "discountingPriceApproval": false,
                "billingToken": null,
                "termsAndConditionsVerId": null,
                "primaryCarrier": null,
                "shippingAddress1": null,
                "shippingAddress2": null,
                "shippingCity": null,
                "shippingState": null,
                "shippingPostalCode": null,
                "simFulfilmentType": null,
                "account": {
                    "sfAccountId": sfAccountId,
                    "internalCustomerId": localStorage.getItem('InternalCustomerId'),
                    "masterAccountId": masterAccountId,
                    "partnerAccountId": "",
                    "productName": null,
                    "companyName": null,
                    "billingAddress1": null,
                    "billingAddress2": null,
                    "billingCity": null,
                    "billingState": null,
                    "billingPostalCode": null,
                    "contactFirstName": null,
                    "contactLastName": null,
                    "contactEmail": null,
                    "businessPhone": null,
                    "mobilePhone": null,
                    "providerPaymentMethod": null,
                    "netTermsDays": null,
                    "mvnOPortalAccess": false,
                    "inventorySource": null,
                    "expirationDate": null
                },
                "charges": null,
                "rateplanOrderItems": resp["response"]["validsims"].split(",").map((value, index, arr) => {

                    return {
                        "description": "",
                        "SIMQuantity": "1",
                        "unitCost": "",
                        "SKU": "",
                        "SIMNumber": value.trim(),
                        "IMEI": null,
                        "replacedSIMNumber": null,
                        "ItemStatus": null,
                        "activationCost": null,
                        "discountMRC": null,
                        "discountMRCSurcharge": null,
                        "discountactivationCost": null,
                        "discountactivationSurcharge": null,
                        "discountDepositMonth": null,
                        "productCode": null,
                        "assetUserName": null,
                        "secondaryID": null
                    }
                }),
                "paymentportal": null,

                "PortalUser": {
                    "PortalUserName": localStorage.getItem('PortalUsername'),
                    "FirstName": localStorage.getItem('PortalUsername').split(" ")[0],
                    "LastName": localStorage.getItem('PortalUsername').split(" ")[1],
                    "Email": localStorage.getItem('PortalUserEmail'),
                    "PortalAccountName": localStorage.getItem('PortalUserAccountName'),
                    "PortalAccountId": localStorage.getItem('PortalUserAccountId')

                },

                "opportunity": null,
                "requestSource": "tlm",
                "groupNameValue": []
            }
            let orderRequestCommonJSON = {
                "threadId": uuidv4(),
                "createdDateTime": "",
                "orderType": orderType,
                "provider": "Advantix",
                "payload": { ...payload }

            }
            try {
              
                let orderResp = await createOrder({ orderRequestCommonJson: orderRequestCommonJSON })
              
                if (Object.hasOwn(orderResp, 'payload')) {                  
                    if (orderResp["payload"]["statusType"] === "Complete") 
                        toast.success(formatStatusMessage({ objStatus: orderResp }), toastProps);                                                                  
                    else                      
                        toast.error(formatStatusMessage({ objStatus: orderResp }), toastProps);                                      
                }
            }
            catch (e) {                              
                toast.error(formatStatusMessage({ objStatus: e }));                
            }           
        }
        else {                       
            toast.error(formatStatusMessage({ objStatus: resp }), toastProps);            
        }       
        setShowModal(false);
        history.push("/home");
    }

    return (
        <>
            <LoadingModal showModal={showModal} />
            <Row>
                <Col>
                    <h3>Suspend or Cancel SIM/Rate Plan</h3>
                    <hr ></hr>
                    <Form>
                        
                        <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                                inline
                                label="Suspend SIM"
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                                onClick={(e, suspend, cancel) => handleRadioCheck(e, !suspend, cancel)}
                                checked={!cancel}
                            />
                            <Form.Check
                                inline
                                label="Cancel SIM"
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                                onClick={(e, suspend, cancel) => handleRadioCheck(e, suspend, !cancel)}
                                checked={!suspend}
                            />
                        </div>
                        <Row>
                            <Col lg={10} md={8 }>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Account</Form.Label>
                                    <div>
                                        <Select
                                            className="basic-singles"
                                            classNamePrefix="select"
                                            defaultValue={defOption}
                                            isDisabled={true}
                                            isSearchable={false }
                                            name="Account"
                                            options={options}
                                            onChange={(option) => handleAccountSelect(option)}
                                        />
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row>


                        <Row >
                            <Col lg={ 10} md={8 } >
                                <Form.Group className="mb-3 " controlId="ControlTextarea1">
                                    <div style={{ marginTop: '15px' }} >
                                        <Form.Label >SIM Numbers</Form.Label>
                                        <Form.Control ref={inputRef} as="textarea" rows={3}  />
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={10} md={8 } >
                                <FormGroup >
                                    <Button as="input" type="button" value="Place Order" style={{ float: 'right' }} onClick={(e) => handleSubmit(e.target)} />
                                </FormGroup>
                            </Col>
                        </Row>


                    </Form>

                </Col>
            </Row>
           
        </>
    );
}

export default SuspendOrCancel