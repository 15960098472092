import React, { useRef, useState, useEffect } from 'react';
import { Spinner } from "reactstrap";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import CustomTooltip from './customTooltip';
import "./InventoryTable.css";
import SimLink from "./SimLink";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { Col, Form } from 'react-bootstrap';
import { Card, CardBody } from "reactstrap";
import OverlayMenu from '../common/ContextMenu';

const formatNumber = (number) => {
    return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const usageFormatter = (params) => formatNumber(params.value) + ' MB';

const usernameFormatter = (params) => params.value?.split('|')[0];

const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    tooltipComponent: 'customTooltip',
    flex: 1,
};

const InventoryTable = ({ data, usageData }) => {
    const inventoryGridRef = useRef(null);
    const [showCancelled, setShowCancelled] = useState(false); 
    const [filteredData, setFilteredData] = useState(data);   

    useEffect(() => {
        if (Array.isArray(data)) {  
            if (showCancelled) {
                setFilteredData(data);
            } else {
                setFilteredData(data.filter(sim => sim?.Status !== 'Cancelled'));
            }
        } else {
            setFilteredData([]);
        }
    }, [showCancelled, data]);


    const columnDefs = [
        {
            headerName: 'SIM Number',
            headerTooltip: "SIM Number",
            field: 'PhoneNumber',
            tooltipField: "PhoneNumber",
            minWidth: 200,
            cellRenderer: "SimLink"
        },
        {
            headerName: 'User',
            headerTooltip: "User",
            field: 'UserName',
            valueFormatter: usernameFormatter,
            cellStyle: { marginLeft: '16px' },
            tooltipField: "UserName",
            minWidth: 125
        },
        {
            headerName: 'Rate Plan',
            headerTooltip: 'Rate Plan',
            field: 'RatePlanName',
            tooltipField: "RatePlanName",
            cellStyle: { marginLeft: '16px' },
            minWidth: 250
        },
        {
            headerName: 'MRC',
            headerTooltip: 'MRC',
            field: 'MRC',
            filter: 'agNumberColumnFilter',
            tooltipField: "MRC",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 110,
            minWidth: 80
        },
        {
            headerName: 'Usage',
            headerTooltip: 'Usage',
            field: 'Usage',
            valueFormatter: usageFormatter,
            filter: 'agNumberColumnFilter',
            tooltipField: "Usage",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 110,
            minWidth: 80
        },
        {
            headerName: 'Status',
            headerTooltip: 'Status',
            field: 'Status',
            tooltipField: "Status",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 110,
            minWidth: 80
        },
        {
            headerName: '',
            headerTooltip: "Change Service",
            maxWidth: 80,
            minWidth: 70,
            flex: 1,
            cellRenderer: "OverlayMenu",
            cellStyle: { border: 'none' }
        }
    ];

    const onGridReady = (params) => {
        window.addEventListener('resize', () => {
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            }, 0);
        });
        params.api.sizeColumnsToFit();
    };

    const onBtExport = () => {
        try {
        //const { CustomerName } = usageData;
        inventoryGridRef.current.api.exportDataAsCsv({
            fileName: `Inventory_Report`,
            suppressQuotes: true,
            processCellCallback: (cell) => {
                if (typeof cell.value !== 'number') {
                    if (cell.value && cell.value.indexOf(',') !== -1) {
                        return cell.value.replace(/,/g, "");
                    } else if (cell.column.colId === "PhoneNumber") {
                        return `'${cell.value}'`;
                    }
                    return cell.value;
                }
                return cell.value;
            }
        });
        } catch (error) {
            console.error("Error during CSV export:", error);
        }
    };

    return (
        <>
            <Col lg={12} className="d-flex mb-4 justify-content-between">
                <h4 className="text-secondary">Inventory</h4>
                <Form className="ml-auto">
                    <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Show Cancelled SIMs"
                        checked={showCancelled}
                        onChange={(e) => setShowCancelled(e.target.checked)}
                    />
                </Form>
                <FontAwesomeIcon
                    onClick={onBtExport}
                    icon={faFileExcel}
                    style={{ fontSize: '1.7rem', cursor: 'pointer', marginLeft: '1rem', top: '0.3rem' }}
                />
            </Col>

            <Card>
                <CardBody>
                    <div className="ag-theme-alpine grid">
                        <AgGridReact
                            ref={inventoryGridRef}
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowData={filteredData}
                            frameworkComponents={{
                                customLoadingOverlay: () => <Spinner type="grow" />,
                                customNoRowsOverlay: () => <div>No inventory data</div>,
                                customTooltip: CustomTooltip,
                                SimLink: SimLink,
                                OverlayMenu: OverlayMenu
                            }}
                            loadingOverlayComponent='customLoadingOverlay'
                            noRowsOverlayComponent='customNoRowsOverlay'
                            pagination
                            paginationAutoPageSize
                            enableCellTextSelection
                            tooltipShowDelay={0}
                        />
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default InventoryTable;
