import React, { Fragment, useEffect, useState } from 'react';
import { DropdownItem, DropdownMenu, Form, Input, Dropdown, DropdownToggle, Badge, Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchBox = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="search-box" style={{ display: "none" }}>
      <DropdownToggle tag="div" data-toggle="dropdown" aria-expanded={dropdownOpen}>
        <Form>
          <Input
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input"
            value={searchInputValue}
            onChange={({ target }) => setSearchInputValue(target.value)}
            onClick={() => setDropdownOpen(false)}
          />
          <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
        </Form>
        {searchInputValue && (
          <button className="close" onClick={() => setSearchInputValue('')}>
            <FontAwesomeIcon icon="times" />
          </button>
        )}
      </DropdownToggle>
    </Dropdown>
  );
};

export default SearchBox;
