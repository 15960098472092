import { generateErrorElementWithContactInfo } from "../../helpers/utils";

async function ValidateSIM({ simEntity, requestType, orderId }) {
    console.log("validateSIM");
    let respValidSims = await fetch("api/ChangeServices/ValidateSIMs", {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
            'requestType': requestType,
            'orderId': orderId
        },
        method: "post",
        body: JSON.stringify({
            ...simEntity
        })
    }).then(response => {
        return response.json();
    })
    return respValidSims;
}

export { ValidateSIM };


function formatStatusMessage({ objStatus }) {
    let messageTitle = "Unexpected Error";
    let formatResp = Object.getOwnPropertyNames(objStatus).map((v, i, arr) => {
        if (v === "response") {
            messageTitle = "Order Failed";
            if (Object.hasOwn(objStatus['response'], 'invalidsims'))
                return objStatus[v]["invalidsims"].split("|");
            else {
                return objStatus[v].split("|");
            }
        }
        else if (v === "message") {
            let errName = "";
            messageTitle = "Order Failed";
            if (Object.hasOwn(objStatus, 'name')) {
                errName = objStatus["name"];
            }
            if (errName !== "") {
                if (errName.toLowerCase() === "Invalid sim(s) ") {

                    let mesarr = [errName].concat(objStatus["message"].split("|"));
                    return mesarr;
                }
                else {
                    let mes = errName.concat(" : ").concat(objStatus["message"]);
                    return mes;
                }


            }
            else {
                return "Unexpected error : ".concat(objStatus["message"]);
            }

        }
        else if (v === "payload" && objStatus["payload"]["statusType"] === "Fail") {
            messageTitle = "Order Failed";
            return objStatus["payload"]["errorCode"] + ": " + objStatus["payload"]["errorDescription"];
        }
        else if (v === "payload" && objStatus["payload"]["statusType"] === "Complete" && objStatus["payload"]["internalOrderId"]) {
            messageTitle = "Order processed.";
            return "Internal Order ID: " + objStatus["payload"]["internalOrderId"];

        }
        else if (v === "payload" && objStatus["payload"]["statusType"] === "Complete" && objStatus["payload"]["CaseNumber"]) {
            messageTitle = "Case created Successfully";
            return "Case Number: " + objStatus["payload"]["CaseNumber"];

        }
        else if (v === "statusType") {
            messageTitle = "Unexpected Error";
            if (objStatus["statusType"] === "Error" || objStatus["statusType"] === "Exception")
                return objStatus["errorMessage"];
            else if (objStatus["statusType"] === "Fail" || objStatus["statusType"] === "RetryError")
                return objStatus["errorDescription"];
            else
                return "Unable to load required data"
        }
        else {
            return "";
        }
    })

    let contactInfo = "";
    if (messageTitle.toLowerCase() === "order failed" || messageTitle.toLowerCase() === "unexpected error")
        contactInfo = generateErrorElementWithContactInfo("For help with this error");
    return (
        <>
            <h4 style={{ color: "whitesmoke" }}>{messageTitle}</h4>
            <div style={{ display: "flex", flexDirection: "column" }}>
                {formatResp.map((v, i, arr) => {

                    return <div key={i}><span key={i}>{arr[i]}</span></div>
                })}
            </div>
            {contactInfo}
        </>
    )
}

export { formatStatusMessage }