import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import SettingsAnimatedIcon from './SettingsAnimatedIcon';

const TopNavRightSideNavItem = ({ setSideModalOpen }) => {
    return (
        <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center" style={{ justifyContent: 'flex-end' }}>
            <NavItem>
                <SettingsAnimatedIcon setSideModalOpen={setSideModalOpen} />
            </NavItem>
        </Nav>
      
    );
};

export default TopNavRightSideNavItem;