import React, { Component } from 'react';
import { Row ,Col} from 'react-bootstrap';
import { throwErrorIfNon200Response, themeColors, generateErrorElementWithContactInfo } from "../../helpers/utils";
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
//import 'react-calendar/dist/Calendar.css';
import "./Usage.css"
import "../inventory/InventoryTable.css";
import moment from "moment"
import ReportDate from "./ReportDate"
import UsageFilter from "./UsageFilter"
import UsageTable from "./UsageTable"
import DisplayOption from "./DisplayOptions"
import HighUsageInput from "./HighUsageInput"
import {  toast } from 'react-toastify';


class Usage extends Component {
    constructor(props) {
        super(props);
        document.title = "Usage - Summary"
    }
    state = {
        detailData: null,
        isDetailDataLoaded: false,
        mode: "",
        usageInput: '',
        modeTitle: '',
        reportStartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        reportEndDate: new Date(),
        isStartDateOpen: false,
        isEndDateOpen: false,
        renderView: null,
        isFilterOptionsOpen: false,
        enableAssetWithUsage: false,
        isrenderViewOpen: false,
        filterOption: '',
        unfilterData: null,
        displayFilterButton: false,
        filterOptionElement: null,
        iconThreshold: 0,
        enableIconDataFilter: false,
        isOptionOpen: false,
        isStartDateChanged: false,
        isEndDateChanged:false,
        isModeChanged:false
    }



    componentDidMount() {
        this.setState({ modeTitle: 'Summary', mode: "summary", filterOption:'All Assets'}, () => {
            this.fetchDetailData()
       })
    }
    fetchDetailData = (filterOption = '') => {
        this.setState({ isDetailDataLoaded: true, detailData: null },
            () => {
                fetch('api/SmartSIMUsageController/GetUsageReport', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                    },
                    method: 'post',
                    body: JSON.stringify({
                        "StartDate": this.state.reportStartDate,
                        "EndDate": this.state.reportEndDate,
                        "Mode": this.state.mode
                    })

                }).then(throwErrorIfNon200Response).then((response) => {
                    return response.json().then((data) => {
                        if (Array.isArray(data) && data.length > 0) {
                            if (this.state.mode === 'summary') {
                                this.setState({ isDetailDataLoaded: false, detailData: data, unfilterData: data, iconThreshold: data.length > 0 ? parseInt(data[0]['threshold']).toFixed() : 0 })
                            }
                            else {
                                this.setState({ detailData: data, unfilterData: data, isDetailDataLoaded: false })
                            }
                            if (this.state.mode === 'summary' && filterOption !== '')
                                this.handleFilterOption(filterOption, data, this.state.isModeChanged, this.state.mode);
                        }
                        else {
                            this.setState({ isDetailDataLoaded: false, detailData: [] })
                        }
                    });
                }).catch((ex) => {
                    this.setState({ isLoading: false, detailData: [] });
                    toast.error(generateErrorElementWithContactInfo("Error retrieving inventory details."));
                });
            }

        )
    }

   
    handleFilterOption = (filterOption, data, modeChanged, mode) => {
        if (mode != "summary")
            filterOption = 'All Assets'; 
        switch (filterOption) {
            case 'All Assets':
                this.setState({ detailData: data, enableAssetWithUsage: false, }, () => {
                    this.handleResetFilterOption()
                    if (!modeChanged) {
                        this.handleOpetionOpen()
                        this.setState({ isModeChanged: false })
                    }
                    

                })
                break;
            case 'Assets with Usage':
                const filterArry = data.filter(data => parseInt(data['UsageQuantity']) >= parseInt(this.state.iconThreshold))
                this.setState({ detailData: filterArry, enableAssetWithUsage: false, }, () => {
                    this.handleResetFilterOption()
                    if (!modeChanged) {
                        this.handleOpetionOpen()
                        this.setState({ isModeChanged: false })
                    }
                   
                })
                break;
            case 'Assets with Zero':                
                let filterArray = data !== null && data.length > 0 ? data.filter(data => parseFloat(data['UsageQuantity']) === parseFloat("0")) : []
                
                filterArray = filterArray.length === 0 ? [] : filterArray;
                
                this.setState({ detailData: filterArray, }, () => {
                    this.handleResetFilterOption()
                    if (!modeChanged) {
                        this.handleOpetionOpen()
                        this.setState({ isModeChanged:false })
                    }
                    
                    
                })
                break;
            default:
                this.handleOpetionOpen()
                break;

        }
    }
    handleStartDateSelection = (date) => {
        this.setState({ reportStartDate: date, isStartDateChanged:true }, () => {
        })
    }

    handleEndDateSelection = (date) => {        
        this.setState({ reportEndDate: date, isEndDateChanged:true })
    }




    handleFilter = (event) => {
        
        event.preventDefault()
        let stDateChanged = this.state.isStartDateChanged
        let endDateChanged = this.state.isEndDateChanged 
        if (stDateChanged)
            this.setState({ isStartDateChanged:false })            
        if (endDateChanged)
            this.setState({ isEndDateChanged:false })            
        if (this.state.unfilterData === null || (stDateChanged || endDateChanged)) {
            this.setState({ isDetailDataLoaded: true, detailData: null },
                () => {
                    fetch('api/SmartSIMUsageController/GetUsageReport', {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                        },
                        method: 'post',
                        body: JSON.stringify({
                            "StartDate": this.state.reportStartDate,
                            "EndDate": this.state.reportEndDate,
                            "Mode": this.state.mode
                        })

                    }).then(throwErrorIfNon200Response).then((response) => {
                        return response.json().then((data) => {
                          
                            if (Array.isArray(data) && data.length > 0) {
                                if (this.state.mode === 'summary') {
                                    this.setState({ isDetailDataLoaded: false, detailData: data, unfilterData: data, iconThreshold: data.length > 0 ? parseInt(data[0]['threshold']).toFixed() : 0, defaultdata: data })
                                    if (this.state.enableIconDataFilter) {
                                        const filterArry = data !== null ?
                                            data.filter(data => parseInt(data['UsageQuantity']) >= parseInt(this.state.iconThreshold)) :
                                            []
                                        
                                        this.setState({ detailData: filterArry, enableAssetWithUsage: false }, () => {
                                            this.handleResetFilterOption()
                                            //this.handleOpetionOpen()
                                        })
                                    }
                                }
                                else {
                                    this.setState({ detailData: data, unfilterData: data, isDetailDataLoaded: false })
                                }
                                this.handleFilterOption(this.state.filterOption, data, false, this.state.mode);
                            }
                            else {
                                this.setState({ isDetailDataLoaded: false, detailData: [] })
                            }                            

                        });
                    }).catch((ex) => {
                        this.setState({ isLoading: false, detailData: [] });
                        toast.error(generateErrorElementWithContactInfo("Error retrieving inventory details."));
                    });
                }

            )
        }
        else {
            const tempArrayData = this.state.unfilterData            
            if (this.state.enableIconDataFilter || this.state.mode === 'summary') {
                const filterArry = tempArrayData !== null ?
                    tempArrayData.filter(data => parseInt(data['UsageQuantity']) >= parseInt(this.state.iconThreshold)) :
                    []                
                this.setState({ detailData: filterArry, enableAssetWithUsage: false }, () => {
                    //this.handleResetFilterOption()
                    //this.handleOpetionOpen()
                })
            }
            this.handleFilterOption(this.state.filterOption, tempArrayData, false, this.state.mode);

        }
    }

    setFilterOption = (event) => {
        const input = event.target;
        const labelValue = input.getAttribute('data-name')
        if (labelValue === 'Assets with Usage') {
            input.value = true
            this.setState({ filterOption: labelValue, enableAssetWithUsage: true, displayFilterButton: true, filterOptionElement: event.target })
        }
        else {
            input.checked = true
            this.setState({ filterOption: labelValue, enableAssetWithUsage: false,  displayFilterButton: true, filterOptionElement: event.target })
        }
    }
    handleResetFilterOption = () => {
       this.setState({ isFilterOptionsOpen: !this.state.isFilterOptionsOpen, displayFilterButton: false, })
    }


    handleReportDateSearch = () => {
        if (moment(this.state.reportEndDate).isAfter(this.state.reportStartDate)) {
            this.fetchDetailData()
        }
    }

    handleRenderView = () => {
        this.setState({ isrenderViewOpen: !this.state.isrenderViewOpen }, () => {
        })
    }
    handleRenderViewSelection = (modeTitle, mode) => {
        this.setState({ mode: mode, modeTitle: modeTitle, isrenderViewOpen: false, detailData: [], isModeChanged: true }, () => {
            this.fetchDetailData(this.state.filterOption)
        })

    }

    handleUsageInput = (event) => {
        this.setState({ [event.target.id]: event.target.value === '' ? 0 : event.target.value,   enableIconDataFilter: event.target.id === 'iconThreshold' ? true : false ,  }, () => {
            if (this.state.enableIconDataFilter ) {
                this.setState({ filterOption: 'Assets with Usage' })
            }
        })
    }
    handleResetFilter = () => {
        const tempData = this.state.unfilterData
        this.setState({ detailData: tempData, displayFilterButton: false })
    }
  
    handleOpetionOpen = () => {
        this.setState({ isOptionOpen: !this.state.isOptionOpen })
    }
 




    Opentoast = () => {

        toast.info(
             <div style={{display:"flex", alignItems:"center"} }>
                <div onClick={this.openTosta} style={{ width: '2rem', height:"auto", position:'relative' }} className="usage-pulse">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ fill: themeColors.primary, }}  className="usage-info"  >
                        <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 
                            32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 
                            24-24h16v-64H224c-13.25
                            0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z" />
                    </svg>
                </div>
                <span style={{ marginLeft: "1rem", fontWeight: "bold", color:'#177198'}}>To change the date range of this data, click the
                    <svg style={{ width: '1rem', cursor: 'pointer', fill: themeColors.primary, marginRight: '0.3rem', marginLeft: '0.3rem' }} type="button"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 
                            501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8
                            425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z" />
                    </svg> icon on the right side of the screen</span>

                </div>,{

                position: 'top-center',
                style: { position: "absolute", width: '100%', zIndex: 999, height: '5rem', top: '3rem', },
                toastId: 'customId',
                autoClose: 5000,
                pauseOnHover: false,
                onOpen: () => {
                let toastContent = document.querySelector('#customId');
                let toastButton = document.querySelector('.Toastify__close-button')
                toastContent.style.setProperty("background-color", "#D4F2FF", "important");
                toastContent.style.setProperty("border-radius", "10rem", "important");
                let parent = document.querySelector('#container-toats');
                    if (toastContent) {
                        parent.appendChild(toastContent)
                    if (toastButton) {
                        toastButton.style.setProperty("align-self", "center")

                    }
                }
            }
        });

    
    }
  
    render() {
       
        return (
            <>
                <div id="container-toats" style={{position:'relative'} }>
                </div>
                <DisplayOption isOptionOpen={this.state.isOptionOpen}
                    closeOption={this.handleOpetionOpen} reportStartDate={this.state.reportStartDate}
                    reportEndDate={this.state.reportEndDate}
                    handleFilter={this.handleFilter} >

                    <Row style={{ alignItems: "flex-end" }}>
                        {this.state.mode === 'summary' && (
                            <Col lg={7} md={12} xl={4} xxl={3} xs={12}  >
                                <UsageFilter
                                    filterOption={this.state.filterOption}
                                    defaultSelection={this.state.defaultSelection}
                                    setFilterOption={this.setFilterOption}
                                    displayFilterButton={this.state.displayFilterButton}
                                    handleFilter={this.handleFilter} />
                            </Col>
                        )}
                        
                            <ReportDate handleStartDateSelection={this.handleStartDateSelection}
                                handleEndDateSelection={this.handleEndDateSelection}
                                reportstartDate={this.state.reportStartDate}
                                reportEndDate={this.state.reportEndDate} />
                       
                        {this.state.mode === 'summary' && (
                            <HighUsageInput mode={this.state.mode} iconThreshold={this.state.iconThreshold}
                                handleUsageInput={this.handleUsageInput} />
                        )}
                    </Row>
                </DisplayOption>
                <UsageTable
                    isOptionOpen={this.state.isOptionOpen }
                    Opentoast={this.Opentoast}
                    modeTitle={this.state.modeTitle}
                    usageData={this.props.usageData}
                    isrenderViewOpen={this.state.isrenderViewOpen}
                    handleRenderView={this.handleRenderView}
                    handleRenderViewSelection={this.handleRenderViewSelection}
                    detailData={this.state.detailData}
                    mode={this.state.mode}
                    handleOption={this.handleOpetionOpen}           
                    threshold={this.state.iconThreshold}/>

 </>
        )
    }
}



export default Usage;

