import React from "react";
import { Col, Row } from "reactstrap";
import classNames from 'classnames';

export const FieldRow = ({ title, isLastItem, children }) => (
    <Row>
        <Col>
            <p
                className={classNames('font-weight-semi-bold', {
                    'mb-0': isLastItem,
                    'mb-1': !isLastItem
                })}
            >
                {title}
            </p>
        </Col>
        <Col>{children}</Col>
    </Row>
);