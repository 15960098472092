import { Col } from 'react-bootstrap';
import { themeColors } from "../../helpers/utils";
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import FalconTooTip from "../falcon/common/ToolTip"
import React, { useRef } from 'react';


const HighUsageInput = ({ iconThreshold, handleUsageInput, }) => {
    const icondisplay = useRef(null)


    return (
        <Col lg={4} md={8} xl={3} xxl={3} xs={12} style={{ backgroundColor: "", position: "realtive", top: "-9px" }} className="usage-icon-container mt-4">
            <span style={{ position: 'relative', whiteSpace: "nowrap", left: '0.5rem', fontWeight: "bold" }}>High Usage Threshold

                <div ref={icondisplay} style={{ width: '1.1rem', position: 'absolute', right: -20, top: -13 }} className="pulse" >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ fill: themeColors.primary }}  >
                        <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 
                                                32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 
                                                24-24h16v-64H224c-13.25
                                                0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z" /></svg>

                    <FalconTooTip target={icondisplay}>
                        <span>Used to control Usage Indicator Icons and Usage Filtering (if applicable) </span>
                    </FalconTooTip>
                </div>
            </span>
            <FormGroup controlId="iconThreshold" style={{ margin: 0, width: "10rem" }} className='summary-threshold-field' data-name='iconThreshold'  >
                <FormControl autoComplete='off' className=" input-field usage-input-field usage-icon-container form-high-usage" style={{ marginTop: 0, }}
                    autoFocus type="number" onChange={handleUsageInput} value={iconThreshold} />
            </FormGroup>
        </Col>)

}

export default HighUsageInput 
