import React, { useState , useEffect,useRef} from 'react'
import { Row, Col, Button,  FormGroup, Form } from 'react-bootstrap';
import Collapse from 'react-bootstrap/Collapse';
import Select from 'react-select';
import { ValidateSIM, createOrder, LoadingModal, formatStatusMessage } from './ChangeServiceCommon'
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import './ChangeServices.css'
function ChangeCarrier({ componentProps }) {
    const [selectedAccount, setSelectedAccount] = useState(localStorage.getItem("PortalUserAccountName"));
    const [selectedProvider, setSelectedProvider] = useState("Select...");
    const [selectedradio, setSelectedRadio] = useState("Input SIM Numbers")
    const [carrierOptions, setCarrierOptions] = useState([]);
    const [userDescriptionOptions, setUserDescriptionOptions] = useState([]);
    const [selectedUserDescription, setSelectedUserDescription] = useState([]);    
    const defOption = new Option(localStorage.getItem("PortalUserAccountName"), "1", true, true);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const inputref = useRef(null); 
    const toastProps = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }
    let options = [defOption];
    const handleAccountSelect = (option) => {
        
        setSelectedAccount(option.innerHTML);
    }
    const handleProviderSelect = (option) => {        
        setSelectedProvider(option.value);
    }

    const selectedOption = (option) => {
        setSelectedRadio(option)
    }

   
    const handleUserDescriptionSelect = (option) => {        
        setSelectedUserDescription(option);
    }
    useEffect(() => {        
        let currURL = new URL(window.location);
        if (currURL.searchParams.has("sim")) {
            let txtarea = inputref.current
            txtarea.value = currURL.searchParams.get("sim");
        }
        fetch("api/ChangeServices/GetPreferredCarrier", {
            headers: {
                'Content-Type': 'application/json',
            },
            method: "post",
            body: JSON.stringify({
                "sfAccountId": localStorage.getItem('salesforceId'),
            })
        }).then(response => {            
                return response.json(); 
        }).then(data => {                     
            if (data.hasOwnProperty("statusType") && data["statusType"] === "Fail") {
                toast.error(formatStatusMessage({ objStatus: data }), toastProps);
                setCarrierOptions([])
            }
            else {
                const objectProps = Object.values(data);
                const carrierOpts = objectProps.map((val, i, arr) => {
                    return new Option(val, val, false, false);
                })
                setCarrierOptions(carrierOpts);
            }
            

        });
        fetch("api/ChangeServices/GetUserDescriptions", {
            headers: {
                'Content-Type': 'application/json',
            },
            method: "post",
            body: JSON.stringify({
                "sfAccountId": localStorage.getItem('salesforceId'),
            })
        }).then(response => {
            return response.json()
        }).then(data => {           
            const descriptionOptions = [];        
            if (data.hasOwnProperty("statusType") && data["statusType"] === "Fail") {
                toast.error(formatStatusMessage({ objStatus: data }), toastProps);
                setUserDescriptionOptions(descriptionOptions)
            }
            else {
                for (const [key, value] of Object.entries(data)) {
                    descriptionOptions.push(new Option(key, value, false, false));
                }
                setUserDescriptionOptions(descriptionOptions);
            }
            

        })
       
    }, []);

    const handleSubmit = async (e) => {
        let payload = null;        
        let orderRequestCommonJSON = {};        
        let invalidsims = "";
        
        let resp = {};
        let simsToProcess = [];

        setShowModal(true);
        
        if (selectedradio === "Input SIM Numbers") {           
            const txtarea = inputref.current;
            
            try {
                if (txtarea.value === "") {
                    throw new Error("Please enter SIM Number(s) before submitting an order.")
                }

                if (selectedProvider === "Select...") {
                    throw new Error("Please select a provider.");
                }
                let formattedSimnum = String(txtarea.value).replace(/\r\n\t|\n|\r|\t|\s/gm, ",");
                const simEntity = { sfAccountId: "", simNumbers: [], requiredStatus: "" };
                simEntity.sfAccountId = localStorage.getItem('salesforceId');
                const enteredsims = formattedSimnum.split(",");
                enteredsims.forEach((value, index, arr) => {
                    if (value !== "")
                        simEntity.simNumbers.push({ SIM: value.trim(), IMEI: '', PlanSKU: '', Status: '' });
                })
                simEntity.requiredStatus = "Active";              
              

                resp = await ValidateSIM({ simEntity: simEntity, requestType: "CarrierChange" });
                if (Object.hasOwn(resp, 'response') && Object.hasOwn(resp["response"], 'invalidsims')) {
                    invalidsims = resp["response"]["invalidsims"];
                }

                if (invalidsims !== "") {
                    let invalidSimerr = new Error(invalidsims);
                    invalidSimerr.name = "Invalid SIM(s) "
                    throw invalidSimerr;
                }
                if (resp["status"] === "success" &&
                    (Object.hasOwn(resp, 'response') && Object.hasOwn(resp["response"], 'validsims') &&
                        resp["response"]["validsims"] !== "")) {
                    simsToProcess = resp["response"]["validsims"].split(",").map((value, index, arr) => {
                        return value.trim();
                    })
                }
                
            }
            catch (e) {
                setShowModal(false);
                toast.error(formatStatusMessage({ objStatus: e }), toastProps);
                history.push("/home");
                return false;
            }           
        }            
        else {            
            let concatarr = "";            
            selectedUserDescription.forEach((v, i, arr) => {                
                concatarr = concatarr + v.value +",";                                                
            });          
            simsToProcess = concatarr.split(",").filter((v, i, arr) => { return v !== "" });            
        }
        if (simsToProcess.length>0) {
            payload = {
                "account": {
                    "sfAccountId": localStorage.getItem('salesforceId'),
                    "internalCustomerId": localStorage.getItem('InternalCustomerId'),
                    "masterAccountId": localStorage.getItem('masterAccountId'),
                    "partnerAccountId": "",
                    "productName": null,
                    "companyName": selectedAccount,
                    "billingAddress1": "",
                    "billingAddress2": null,
                    "billingCity": null,
                    "billingState": null,
                    "billingPostalCode": null,
                    "contactFirstName": null,
                    "contactLastName": null,
                    "contactEmail": null,
                    "businessPhone": null,
                    "mobilePhone": null,
                    "providerPaymentMethod": null,
                    "netTermsDays": null,
                    "mvnOPortalAccess": false,
                    "inventorySource": null,
                    "expirationDate": null
                },
                "portalUser": {
                    "PortalUserName": localStorage.getItem('PortalUsername'),
                    "FirstName": localStorage.getItem('PortalUserFullname').split(" ")[0],
                    "LastName": localStorage.getItem('PortalUserFullname').split(" ")[1],
                    "Email": localStorage.getItem('PortalUserEmail'),
                    "PortalAccountName": localStorage.getItem('PortalUserAccountName'),
                    "PortalAccountId": localStorage.getItem('PortalUserAccountId')
                },
                "requestSource": "tlm",
                "preferredCarrier": selectedProvider,
                "simNumbers": simsToProcess
            }
                      
            orderRequestCommonJSON = {
                "threadId": uuidv4(),
                "createdDateTime": "",
                "orderType": "CarrierChange",
                "provider": "Advantix",
                "payload": payload

            }
          
        }
        else {
            setShowModal(false);
            toast.error(formatStatusMessage({ objStatus: resp }), toastProps);
            history.push("/home");
            return false;
        }
        if (payload !== null) {
            try {
                let orderResp = await createOrder({ orderRequestCommonJson: orderRequestCommonJSON })                
                if (Object.hasOwn(orderResp, 'payload')) {
                    if (orderResp["payload"]["statusType"] === "Complete") 
                        toast.success(formatStatusMessage({ objStatus: orderResp }), toastProps);                                                                   
                    else                         
                        toast.error(formatStatusMessage({ objStatus: orderResp }), toastProps);                                        
                }
            }
            catch (e) {     
                toast.error(formatStatusMessage({ objStatus: e }), toastProps)                
            }            
        }
        setShowModal(false);        
        history.push("/home");
    }
    return (
        <>
            <LoadingModal showModal={showModal} />
            <Row>
                <Col>
                    <Form>
                        <Row>
                            <Col lg={10} md={8} >
                                <h3> Change Preferred Carrier</h3>
                                <hr></hr>
                                <Form.Group className="mb-3"  controlId="ControlInput1">
                                    <Form.Label>Account</Form.Label>
                                    <div >
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="select.."
                                            isDisabled={true }
                                            isSearchable={false }
                                            name="Account"
                                            defaultValue={defOption}
                                            onChange={(option) => handleAccountSelect(option) }
                                            options={options}
                                        />
                                    </div>
                                </Form.Group>
                                <FormGroup>
                                    <Form.Label>Provider</Form.Label>
                                    
                                    <div >
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="select.."
                                            isSearchable="false"
                                            name="Carrier"
                                            onChange={(option) => handleProviderSelect(option) }
                                            options={carrierOptions}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col lg={10} md={8} >
                                <OptionalContent descriptionOptions={userDescriptionOptions} selectedOption={selectedOption} refProp={inputref} handleUserDescriptionSelect={handleUserDescriptionSelect }></OptionalContent>
                            </Col>

                        </Row>
                        <Row style={{ marginTop: '10px' }}>
                            <Col lg={10} md={8} >
                                <FormGroup>
                                    <Button as="input" type="button" value="Place Order" onClick={(e) => handleSubmit(e) } style={{ float: 'right' }} />
                                </FormGroup>
                            </Col>
                        </Row>
                       
                    </Form>
                </Col>
            </Row>
        </>
    );
}

function OptionalContent({ descriptionOptions, selectedOption, refProp, handleUserDescriptionSelect }) {
   
    const [openSim, setOpenSim] = useState(true);
    const [openDesc, setOpenDesc] = useState(!openSim);    
    const type = "radio";    
    const handleUserDescriptionOptionSelect = (option) => {               
        handleUserDescriptionSelect(option);        
    }
    const handleClick = (e, openSim, openDesc) => {
        e.stopPropagation()
        setOpenSim(openSim);
        setOpenDesc(openDesc);

        if (openSim)
            selectedOption("Input SIM Numbers");

        if (openDesc)
            selectedOption("User Description");
    }
    return (
        <>

            <div className="mt-2">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">

                    <div key={`inline-${type}`} className="mb-3">
                        <Form.Check
                            inline
                            label="Input SIM Numbers"
                            name="group1"
                            type={type}
                            id={`inline-${type}-carrier-1`}

                            aria-controls="Collapse-Content1"
                            aria-expanded={openSim}
                            onClick={(e, openSim, openDesc) => handleClick(e, !openSim, openDesc)}
                            checked={openSim}
                           

                        />
                        <Form.Check
                            inline
                            label="User Description"
                            name="group1"
                            type={type}
                            id={`inline-${type}-carrier-2`}
                            
                            aria-controls="Collapse-Content2"
                            aria-expanded={openDesc}
                            onClick={(e, openSim, openDesc) => handleClick(e, openSim, !openDesc)}
                            checked={openDesc }
                           
                        />
                    </div>
                </Form.Group>
            </div>
            <div >
                <Collapse in={openSim }>
                    <div id="Collapse-Content1" style={{ width: '' }}>
                        <Form.Group className="mb-3" controlId="carrierchange.ControlTextarea1">
                            <Form.Control ref={refProp} as="textarea" rows={2} />
                        </Form.Group>
                       
                    </div>
                   

                </Collapse>
                <Collapse in={openDesc}>
                    <div id="Collapse-Content2" style={{ width: '' }}>
                        <FormGroup className="mb-3">

                            <div >
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"                                   
                                    isSearchable="true"
                                    name="UserDescription"
                                    isMulti
                                    onChange={(option) => handleUserDescriptionOptionSelect(option)}
                                    options={descriptionOptions}
                                />
                            </div>
                        </FormGroup >
                    </div>
                </Collapse>
                
            </div>    

    </>
    
          )
}



export default ChangeCarrier;