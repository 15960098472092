import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'reactstrap';
import Flex from '../common/Flex';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import { faChevronLeft, faAddressBook } from '@fortawesome/free-solid-svg-icons'

const NavbarVerticalMenuItem = ({ route }) => {
    const [open, setOpen] = useState(false);

    const DropMenu = () => {
        return (
            <>
                <span className="nav-link-icon">
                    <FontAwesomeIcon icon={route.icon} />
                </span>
                <span className="nav-link-text">{route.name}</span>
                <Collapse in={open}>
                    <div id="example-collapse-text">
                        Anim
                    </div>
                </Collapse>
            </>
        )


    }

    return (<Flex align="center">

        {route.drop ? DropMenu() :
            route.icon && (
                <span className="nav-link-icon">
                    <FontAwesomeIcon icon={route.icon} />
                </span>
            )}
        {route.drop ? "" : <span className="nav-link-text">{route.name}</span>
}
        {!!route.badge && (
            <Badge color={route.badge.color || 'soft-success'} pill className="ml-2">
                {route.badge.text}
            </Badge>
        )}

    </Flex>)

}


NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    name: PropTypes.string.isRequired
  }).isRequired
};

export default React.memo(NavbarVerticalMenuItem);
