
import React from 'react';
import { Col, Row } from "react-bootstrap";
import "../SimProfile.css";


const PresentationComponent = ({ data, Title, FirstColsm, FirstColmd, FirstCollg, SecondColsm, SecondColmd, SecondCollg }) => {
    return (
        <>
            <h4 className="live-data-header">{Title}</h4>
            {Object.keys(data).map((name, index) => {
                return (
                    <div className="mb-4" key={index}>
                        <Row>
                            <Col sm={FirstColsm} md={FirstColmd} lg={FirstCollg}  >
                                <span className="live-data-title" >{name}:</span>
                            </Col>
                            <Col sm={SecondColsm} md={SecondColmd} lg={SecondCollg} >
                                <span className="live-data-content" >{data[name]}</span>
                            </Col>
                        </Row>
                    </div>)
            })
            }
        </>
    )
}
export default PresentationComponent;