export const routes = [
    {
        name: 'Home',
        to: '/home',
        exact: true,
        icon: 'home',
        drop: false
    },
    {
        name: 'Billing',
        to: '/billing',
        exact: true,
        icon: 'file-invoice-dollar',
        drop: false
    },
    {
        name: 'Inventory',
        to: '/inventory',
        exact: true,
        icon: 'list',
        drop: false
    },

     {
         name: 'Usage',
         to: '/usage',
         exact: true,
         icon: 'chart-bar',
         drop: false
    },
    {
        name: 'Add Services',
        to: '/orderSim',
        exact: true,
        icon: 'sim-card',
        drop: true,
        dropValues: [{ name: "Order Rate Plans", to: "/orderSim" }, { name: "Provisioning Requests", to: "/pendingrequest" }, { name: "Activation Requests", to: "/activate" } ]

    },
    {
        name: 'Change Services',
        to: '/modifysim',
        exact: true,
        icon: 'sim-card',
        drop: true,
        dropValues: [{ name: "Change Rate Plan", to: "/ChangePlan" }, { name: "Change Preferred Carrier", to: "/ChangeCarrier" }, { name: "Suspend or Cancel", to: "/SuspendOrCancel" }, { name: "Reactivate", to: "/ReactivateSIM" }]

    },
    {
        name: 'Troubleshooting',
        to: '/sendAndViewMessage',
        exact: true,
        icon: 'sim-card',
        drop: true,
        dropValues: [{ name: "Send Message", to: "/SendMessageToDevice" }, { name: "View Message Logs", to: "/ViewSMSLogs" }]

    },
    {
        name: 'Account Management',
        to: '/managereports',
        exact: true,
        icon: 'database',
        drop: true,
        dropValues: [{ name: "Submit Support Request", to: "/SubmitSupportRequest" }, { name: "User Management", to: "/AccountAndUserManagement" }]
    },


    // {
    //     name: 'Admin',
    //     to: '/admin',
    //     exact: true,
    //     // icon: 'home',
    // },
];