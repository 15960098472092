import { UncontrolledTooltip } from 'reactstrap';
import React, {useState, useEffect } from 'react';

const FalconToolTip = ({ target, children }) => {
    const [toolTipTarget, setTooltip] = useState(null)
    useEffect(() => {
        if (target.current !=null) {
            console.log(target)
            setTooltip(target)
        }

    }, [target])
    return (
        <>
        {toolTipTarget !== null ? < UncontrolledTooltip
            placement="right"
            target={toolTipTarget}
            trigger="hover" >
                { children}
        </UncontrolledTooltip >:null}
        </>
        )


}


export default FalconToolTip;

/* 
 
 
 
 
 */