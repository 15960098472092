import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import CommonDatePicker from "./DatePicker"
import "./Usage.css"



const CommonStarAndEndDate = ({ handleStartDateSelection, startDate, handleEndDateSelection, EndDate }, props) => {
    return (

        <Row>

            <Col lg={6} md={6} xs={12} >
                <div style={{ marginBottom: '0.5rem', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '2rem', maxWidth: '100%' }} className="date-container">
                    <FontAwesomeIcon icon={faCalendarAlt} fontSize='5x' style={{ fontSize: '1.2rem', marginRight: '0.5rem' }} className='' />
                    <span className="fw-bold" style={{ width: '58%', fontSize: '14px'}} >Start Date</span>
                    <CommonDatePicker className="custom-datepicker-wrapper" handleStartDateSelection={handleStartDateSelection} start={true} startDate={startDate} />

                </div>
            </Col>
            <Col lg={6} md={6} xs={12} >
                <div style={{ marginBottom: '0.5rem', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '2rem', maxWidth: '100%' }} className="date-container">
                    <FontAwesomeIcon icon={faCalendarAlt} fontSize='5x' style={{ fontSize: '1.2rem', marginRight: '0.5rem' }} className='' />
                    <span className="fw-bold" style={{ width: '49%', fontSize: '14px'}}  >End Date</span>
                    <CommonDatePicker className="custom-datepicker-wrapper" handleEndDateSelection={handleEndDateSelection} start={false} EndDate={EndDate} />
                </div>
            </Col>
        </Row>
    );
}



export default CommonStarAndEndDate;
