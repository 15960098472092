import React, { Component } from 'react';
import { BillingAccordion } from "./BillingAccordion";
import { Spinner } from "reactstrap";
import { generateErrorElementWithContactInfo, themeColors } from "../../helpers/utils";
import { toast } from "react-toastify";

export class Billing extends Component {
    static displayName = Billing.name;

    constructor(props) {
        super(props);
        document.title = "Advantix SmartSIM Portal - Billing"
        this.state = {
            Formstatus: 'Fail',
            count: '0',
            loading: false,
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        fetch('/api/BillingController/GetStatements', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post'
        }).then((response) => {
            this.setState({ loading: false });
            return response.json().then((data) => {
                if (typeof Array.isArray(data)) {
                    this.setState({
                        Formstatus: "Complete",
                        count: data.length,
                        data
                    });
                } else {
                    throw new Error("Error retrieving statement data.");
                }
            });
        }).catch((ex) => {
            console.log(`error: ${ex}`);
            this.setState({ loading: false });
            toast.error(generateErrorElementWithContactInfo("Error retrieving statement data."));
        });

    }

    render() {
        const { loading, data } = this.state;

        return (
            <>
                <h4 className="text-secondary mb-3">Billing</h4>
                { loading ?
                    <div className="d-flex mt-11">
                        <Spinner className="m-auto" style={{ color: themeColors.primary }} type="grow"/>
                    </div> :
                    <>
                        { Array.isArray(data) && data.length > 0 ?
                            data.map(currBilling => <BillingAccordion key={currBilling?.BillingId} billing={currBilling} />) :
                            <h4 className="text-center" style={{ color: themeColors.warning }}>No Data</h4>
                        }
                    </>
                }
            </>
        );
    }
}
