
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

const FalconDatePicker = ({ ReportEndDate, ReportstartDate,  start, handleStartDateSelection, handleEndDateSelection }) => {
return (
        <>
            <DatePicker
               selected={start ? ReportstartDate : ReportEndDate}
                onChange={(date:Date) => {
                    start?
                        handleStartDateSelection(date) :
                        handleEndDateSelection(date)
                }}
                formatWeekDay={day => day.slice(0, 3)}
                className='form-control date-font '
                style={{fontSize:'0.8rem'} }
             
                placeholderText="Select Date"
            />

        </>
    );
}


export default FalconDatePicker;
