import React from 'react';
import { Card, CardBody, Spinner } from 'reactstrap';
import { Line } from "react-chartjs-2";
import FalconCardHeader from '../falcon/common/FalconCardHeader';
import Flex from '../falcon/common/Flex';
import './ChartCard.css'
import { rgbaColor } from "../../helpers/utils";

const ChartCard = ({title, data, xDataKey, yDataKey, labelUnit, loading}) => {
    let config = null;
    if (data !== null) {
        config = {
            data: (canvas) => {
                const ctx = canvas.getContext('2d');
                const gradientFill = ctx.createLinearGradient(94, 110, 130, 1);
                gradientFill.addColorStop(0, 'rgba(44, 123, 229, 1)');
                // gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0)');
                let chartData = data;
                if (!Array.isArray(chartData)) {
                    chartData = [];
                }
                return {
                    labels: chartData.map(curr => curr[xDataKey]),
                    datasets: [
                        {
                            borderWidth: 2,
                            data: chartData.map(curr => curr[yDataKey]),
                            // borderColor: rgbaColor('#fff', 0.8),
                            backgroundColor: gradientFill
                        }
                    ]
                };
            },
            options: {
                legend: {display: false},
                tooltips: {
                    mode: 'x-axis',
                    xPadding: 20,
                    yPadding: 10,
                    displayColors: false,
                    callbacks: {
                        label: tooltipItem => `${data[tooltipItem.index][yDataKey]} ${labelUnit}`,
                        title: () => null
                    }
                },
                hover: {mode: 'label'},
                scales: {
                    xAxes: [
                        {
                            scaleLabel: {
                                show: true,
                                labelString: 'Date'
                            },
                            ticks: {
                                fontColor: rgbaColor('#5e6e82', 1),
                                fontStyle: 400
                            },
                            gridLines: {
                                // color: rgbaColor('#fff', 0.1),
                                // zeroLineColor: rgbaColor('#fff', 0.1),
                                lineWidth: 1
                            }
                        }
                    ],
                    yAxes: [
                        {
                            display: false,
                            gridLines: {
                                // color: rgbaColor('#fff', 1)
                            }
                        }
                    ]
                },
            },
            responsive: true,
        };
    };

    return (
        <Card className="chart-card">
            <FalconCardHeader className="pb-0 text-center" title={title} light={false} titleTag="h6"/>
            <CardBody tag={Flex} align="end">
                <div className="chart-container">
                    {!loading ? <Line data={config?.data} options={config?.options} /> : <Spinner type="grow" />}
                </div>
            </CardBody>
        </Card>
    );
};

export default ChartCard;
