import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, FormGroup, Form, Button } from 'react-bootstrap';
import { ValidateSIM, formatStatusMessage, LoadingModal, viewMessage, sendMessage } from '../ChangeServices/ChangeServiceCommon.js'
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CommonStarAndEndDate from "../common/StartEndDate"
import { generateErrorElementWithContactInfo, throwErrorIfNon200Response } from "../../helpers/utils";
import ViewMessageLogsTable from "./ViewSMSLogsTable"

function ViewMessageLogs() {
    const [ViewMessageLogsJson, setViewMessageLogsJson] = useState('');
    const inputrefSIM = useRef('');
    const sfAccountId = localStorage.getItem('salesforceId');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isInvalidNumber, setIsInvalidNumber] = useState(false);

    useEffect(() => {
        setViewMessageLogsJson([]);
        let currURL = new URL(window.location);
        if (currURL.searchParams.has("sim")) {
            let txtSIMarea = inputrefSIM.current
            txtSIMarea.value = currURL.searchParams.get("sim");
        }
    }, []);

    const handleNumberInputChange = () => {
        setIsInvalidNumber(false);
    };

    const fetchMessageLogs = async (e) => {
        try {
            const txtSIMNum = inputrefSIM.current;
            if (txtSIMNum.value === "") {
                setIsInvalidNumber(true)
                throw new Error("Please enter a valid SIM Number or Phone Number.")
            }
            let viewMessageEntity = { "DeviceId": txtSIMNum.value, "sfAccountId": sfAccountId, "StartDate": startDate, "EndDate": endDate }

            let data = await viewMessage({ objViewMessageEntity: viewMessageEntity })
            if (Array.isArray(data)) {
                if (data.length > 0) {
                    setViewMessageLogsJson(data);
                }
            } else {
                throw new Error("Error retreiving message logs. Please reach out to Advantix Support for assistance.");
            }
        }
        catch (e) {
            toast.error(e.message);
        }

    }

    const handleStartDateSelection = (date) => {
        setStartDate(date);
    };

    const handleEndDateSelection = (date) => {
        setEndDate(date);
    };
    return (
        <>
            <Row>
                <Col >
                    <h3>Message Logs</h3>
                    <hr />
                    <Form>
                        <Row className="align-items-end">
                            <Col sm={6} lg={7} md={6}  >
                                <CommonStarAndEndDate
                                    handleStartDateSelection={handleStartDateSelection}
                                    handleEndDateSelection={handleEndDateSelection}
                                    startDate={startDate}
                                    EndDate={endDate}
                                />
                            </Col>
                            <Col sm={6} lg={5} md={6} >
                                <div className="d-flex align-items-end justify-content-between" style={{ marginBottom: '0.5rem' }}>
                                    <Form.Label style={{ width: '59%' }} className="fw-bold" >Phone Number:</Form.Label>
                                    <Form.Control style={{ border: isInvalidNumber ? '1px solid red' : '1px solid rgb(135, 163, 195)', marginRight: '7px' }} type="text" ref={inputrefSIM} onChange={handleNumberInputChange} />
                                    <Button variant="primary" type="button" onClick={fetchMessageLogs}>Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>


            <ViewMessageLogsTable data={ViewMessageLogsJson} />
        </>

    );
}

export default ViewMessageLogs;
