import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { toast } from "react-toastify";
import { throwErrorIfNon200Response, themeColors, generateErrorElementWithContactInfo } from "../../helpers/utils";
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import { v4 as uuidv4 } from 'uuid';
import { Spinner } from "reactstrap";

const SessionHistory = ({ show, onHide }) => {
    const [columnDefs, setColumnDefs] = useState([]);
    const [sessionData, setSessionData] = useState(null);
    const [dataSource, setDataSource] = useState('');
    const location = useLocation();
    const [selectedDateRange, setSelectedDateRange] = useState({ value: "1", label: 'Last 1 day' });
    const [loading, setLoading] = useState(true);

    let simNum;

    useEffect(() => {
        if (show) {
            fetchDataSourceInfo();
        }
    }, [show]);

    useEffect(() => {
        if (dataSource) {
            setColumnDefsBasedOnVendor(dataSource);
            fetchSessionData(selectedDateRange.value);
        }
    }, [dataSource, selectedDateRange]);

    const fetchDataSourceInfo = () => {
        try {
            debugger;
            simNum = location.pathname.split("/")[2];
            fetch('/api/SmartSIMInventoryController/GetDataSource', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                },
                method: 'post',
                body: JSON.stringify({ "SIMNumber": simNum })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.text();
                })
                .then(data => {
                    if (data) {
                        setDataSource(data);
                    } else {
                        setDataSource('');
                    }
                })
                .catch(ex => {
                    setDataSource('');
                    toast.error(generateErrorElementWithContactInfo("Error retrieving Datasource for selected SIMNumber."));
                });
        }
        catch (e) {
            toast.error(e.message);
        }
    };

    const setColumnDefsBasedOnVendor = (vendor) => {
        if (vendor === 'Webbing') {
            setColumnDefs([
                { headerName: 'Start Date/Time', cellStyle: { marginLeft: '16px' }, maxWidth: 160, field: 'sessionStartDate' },
                { headerName: 'End Date/Time', cellStyle: { marginLeft: '16px' }, maxWidth: 160, field: 'sessionEndDate' },
                { headerName: 'ICCID', maxWidth: 170, cellStyle: { marginLeft: '16px' }, field: 'iccid' },
                { headerName: 'IMEI', maxWidth: 170, cellStyle: { marginLeft: '16px' }, field: 'imei' },
                { headerName: 'Carrier', maxWidth: 130, cellStyle: { marginLeft: '16px' }, field: 'carrier' },
                { headerName: 'Vendor', maxWidth: 130, cellStyle: { marginLeft: '16px' }, field: 'vendor' },
                { headerName: 'Model', maxWidth: 120, cellStyle: { marginLeft: '16px' }, field: 'model' },
                { headerName: 'Country', maxWidth: 120, cellStyle: { marginLeft: '16px' }, field: 'country' },
                { headerName: 'MCCMNC', maxWidth: 130, cellStyle: { marginLeft: '16px' }, field: 'mccmnc' },
                {
                    headerName: 'Usage(KB)',
                    maxWidth: 120,
                    cellStyle: { marginLeft: '-22px', textAlign: 'right' },
                    field: 'usage',
                    valueFormatter: (params) => {
                        const value = params.value;
                        if (value !== undefined && value !== null) {
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        }
                        return '';
                    }
                }
            ]);
        } else if (vendor === 'KORE Wireless') {
            setColumnDefs([
                { headerName: 'ICCID', cellStyle: { marginLeft: '16px' }, field: 'ICCID' },
                { headerName: 'MSISDN', cellStyle: { marginLeft: '16px' }, field: 'MSISDN' },
                { headerName: 'SessionId', cellStyle: { marginLeft: '16px' }, field: 'SessionId' },
                { headerName: 'Start Date/Time', cellStyle: { marginLeft: '16px' }, field: 'SessionStartTime' },
                { headerName: 'End Date/Time', cellStyle: { marginLeft: '16px' }, field: 'SessionEndTime' },
                { headerName: 'APN', cellStyle: { marginLeft: '16px' }, field: 'APN' }
            ]);
        }
    };

    const fetchSessionData = (dateRange) => {
        try {
            setLoading(true);
            simNum = location.pathname.split("/")[2];
            const payloadData = {
                "threadId": uuidv4(),
                "createdDateTime": new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
                "provider": dataSource === "Webbing" ? "advantixsmartsim" : "kore wireless",
                "transactionType": "SessionDetails",
                "payload": {
                    "SIMNo": simNum,
                    "dateRange": dateRange
                }
            };

            fetch('/api/SmartSIMInventoryController/GetSessionData', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                },
                method: 'post',
                body: JSON.stringify(payloadData)
            })
                .then(throwErrorIfNon200Response)
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setSessionData(data);
                    } else {
                        setSessionData([]);
                    }
                    setLoading(false);
                })
                .catch(ex => {
                    setSessionData([]);
                    setLoading(false);
                    toast.error(generateErrorElementWithContactInfo("Error retrieving session data."));
                });
        }
        catch (e) {
            toast.error(e.message);
        }
    };

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true
    };

    const handleDateRangeChange = (selectedOption) => {
        setSelectedDateRange(selectedOption);
    };

    const dateRangeOptions = [
        { value: "1", label: 'Last 1 day' },
        { value: "3", label: 'Last 3 days' },
        { value: "7", label: 'Last 7 days' }
    ];

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="flex-grow-1">Session History: {location.pathname.split("/")[2]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Form.Group className="mb-2 d-flex align-items-center" style={{ marginLeft: '70%' }}>
                        <Form.Label className="mr-2 mb-0">Date Range</Form.Label>
                        <Select
                            className="basic-single"
                            isSearchable={false}
                            name="Date Range"
                            options={dateRangeOptions}
                            defaultValue={selectedDateRange}
                            onChange={handleDateRangeChange}
                        />
                    </Form.Group>
                </Row>
                <Row>
                    {loading ? (
                        <div className="d-flex" style={{ width: '100%' }}>
                            <Spinner className="m-auto" style={{ color: themeColors.primary }} type="grow" />
                        </div>
                    ) : (
                        <div className="ag-theme-alpine" style={{ height: 530, width: '100%' }}>
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={sessionData}
                                defaultColDef={defaultColDef}
                                frameworkComponents={{
                                    customNoRowsOverlay: () => <div>No Session History is able to be found for this SIM. Please reach out to Advantix Connectivity Support for assistance.</div>
                                }}
                                noRowsOverlayComponent="customNoRowsOverlay"
                                pagination
                                paginationAutoPageSize
                                enableCellTextSelection
                            />
                        </div>
                    )}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SessionHistory;
