import React, { useState,useEffect,useRef } from 'react'
import { Row, Col, Button, FormGroup, Form } from 'react-bootstrap';
import { toast } from "react-toastify";
import Select from 'react-select';
import { LoadRatePlans, ValidateSIM, createOrder, LoadingModal, formatStatusMessage } from './ChangeServiceCommon'
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from "react-router-dom";
import './ChangeServices.css'
import LoadProducts from "../common/ProductSelection";
function ReactivateSuspendedSIM({ componentProps }) {
    const [selectedAccount, setSelectedAccount] = useState(localStorage.getItem("PortalUserAccountName"));
    const [selectedRatePlan, setSelectedRatePlan] = useState({ description: "", skuNumber: "" });
    const [accountOptions, setAccountOptions] = useState([]);
    const masterAccountId = localStorage.getItem('masterAccountId');
    const sfAccountId = localStorage.getItem('salesforceId');
    const defOption = new Option(localStorage.getItem("PortalUserAccountName"), "1", true, true);
    const inputRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    const handleAccountSelect = (option) => {        
        setSelectedAccount(option.innerHTML);        
    }

    useEffect(() => {
        setAccountOptions([new Option(localStorage.getItem("PortalUserAccountName"), "1", true, true)])
        let currURL = new URL(window.location);
        if (currURL.searchParams.has("sim")) {
            let txtarea = inputRef.current
            txtarea.value = currURL.searchParams.get("sim");
        }
       
    },[])

    const handleSubmit = async () => {
        const txtarea = inputRef.current;
        setShowModal(true);       
        let invalidsims = "";
        let resp = {};        
        const toastProps = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        }
        try {
            if (txtarea.value === "") {
                throw new Error("Please enter SIM Number(s) before submitting an order.");
            }
            if (selectedRatePlan.description === "" || selectedRatePlan.skuNumber === "") {
                let rperr = new Error("Please select a Rate Plan before submitting an order.");
                rperr.name = "RatePlan"
                throw rperr
            }
            let formattedSimnum = String(txtarea.value).replace(/\r\n\t|\n|\r|\t|\s/gm, ",");
           
            const simEntity = { sfAccountId: "", simNumbers: [], requiredStatus: "" };
            simEntity.sfAccountId = sfAccountId;
            const enteredsims = formattedSimnum.split(",");
            enteredsims.forEach((value, index, arr) => {
                if (value !== "")
                    simEntity.simNumbers.push({ SIM: value.trim(), IMEI: '', PlanSKU: '', Status: '' });
            })

            simEntity.requiredStatus = "Active";
            
            resp = await ValidateSIM({ simEntity: simEntity, requestType: "Reactivate" })
            if (Object.hasOwn(resp, 'response') && Object.hasOwn(resp["response"], 'invalidsims')) {
                invalidsims = resp["response"]["invalidsims"];
            }

            if (invalidsims !== "") {
                let invalidSimerr = new Error(invalidsims);
                invalidSimerr.name = "Invalid SIM(s) "
                throw invalidSimerr;
            }
        }
        catch (e) {            
            toast.error(formatStatusMessage({ objStatus: e }), toastProps);
            //history.push("/home");
            setShowModal(false);
            return false;
        }        
        if (resp["status"] === "success" &&
            (Object.hasOwn(resp, 'response') && Object.hasOwn(resp["response"], 'validsims') &&
                resp["response"]["validsims"] !== "")) {
            let payload = {
                "orderId": "",
                "orderNumber": "",
                "internalOrderId": null,
                "accountSetupFee": null,
                "replacementFee": null,
                "overageRatePlanCost": null,
                "suspendPlanCost": null,
                "discountingPriceApproval": false,
                "billingToken": null,
                "termsAndConditionsVerId": null,
                "primaryCarrier": null,
                "shippingAddress1": null,
                "shippingAddress2": null,
                "shippingCity": null,
                "shippingState": null,
                "shippingPostalCode": null,
                "simFulfilmentType": null,
                "account": {
                    "sfAccountId": sfAccountId,
                    "internalCustomerId": localStorage.getItem('InternalCustomerId'),
                    "masterAccountId": masterAccountId,
                    "partnerAccountId": "",
                    "productName": null,
                    "companyName": null,
                    "billingAddress1": null,
                    "billingAddress2": null,
                    "billingCity": null,
                    "billingState": null,
                    "billingPostalCode": null,
                    "contactFirstName": null,
                    "contactLastName": null,
                    "contactEmail": null,
                    "businessPhone": null,
                    "mobilePhone": null,
                    "providerPaymentMethod": null,
                    "netTermsDays": null,
                    "mvnOPortalAccess": false,
                    "inventorySource": null,
                    "expirationDate": null
                },
                "charges": null,
                "rateplanOrderItems": resp["response"]["validsims"].split(",").map((value, index, arr) => {

                    return {
                        "description": selectedRatePlan.description,
                        "SIMQuantity": "1",
                        "unitCost": "",
                        "SKU": selectedRatePlan.skuNumber,
                        "SIMNumber": value,
                        "IMEI": null,
                        "replacedSIMNumber": null,
                        "ItemStatus": null,
                        "activationCost": null,
                        "discountMRC": null,
                        "discountMRCSurcharge": null,
                        "discountactivationCost": null,
                        "discountactivationSurcharge": null,
                        "discountDepositMonth": null,
                        "productCode": null,
                        "assetUserName": null,
                        "secondaryID": null
                    }
                }),
                "paymentportal": null,

                "PortalUser": {
                    "PortalUserName": localStorage.getItem('PortalPortalUserFullnameUsername'),
                    "FirstName": getUserName(localStorage.getItem('PortalUserFullname'), "firstname"),
                    "LastName": getUserName(localStorage.getItem('PortalUserFullname'), "lastname"),
                    "Email": localStorage.getItem('PortalUserEmail'),
                    "PortalAccountName": localStorage.getItem('PortalUserAccountName'),
                    "PortalAccountId": localStorage.getItem('PortalUserAccountId')

                },

                "opportunity": null,
                "requestSource": "tlm",
                "groupNameValue": []
            }
            const orderRequestCommonJSON = {
                "threadId": uuidv4(),
                "createdDateTime": "",
                "orderType": "Reactivate",
                "provider": "Advantix",
                "payload": { ...payload }

            }           
            try {               
                let orderResp = await createOrder({ orderRequestCommonJson: orderRequestCommonJSON })                
                if (Object.hasOwn(orderResp, 'payload')) {
                    if (orderResp["payload"]["statusType"] === "Complete") {
                        
                        toast.success(formatStatusMessage({ objStatus: orderResp }), toastProps);
                        history.push("/home");
                    }
                    else {
                        
                        toast.error(formatStatusMessage({ objStatus: orderResp }), toastProps);
                        //history.push("/home");
                    }
                }                               
            }
            catch (e) {               
                setShowModal(false);
                toast.error(formatStatusMessage({ objStatus: e }), toastProps)
                //history.push("/home");
            }            
        }
        else {           
            setShowModal(false);           
            toast.error(formatStatusMessage({ objStatus: resp }), toastProps)
            //history.push("/home");
        }
        setShowModal(false);
    }
   
   
    const getUserName = (userFullName,namePart) => {
        
        if (userFullName.split(" ").length > 0) {
            if (namePart === "firstname")
                return userFullName.split(" ")[0];
            else if (namePart === "lastname")
                return userFullName.split(" ")[1];
            else
                return userFullName;
        }        
        else
            return userFullName;
    }

    const handleRatePlanSelect = (ratePlan) => {
        setSelectedRatePlan(ratePlan);
    };
    return (
        <>
            <LoadingModal showModal={showModal} />


            
            <Row>
                <Col >
                    <h3> Reactivate Suspended SIMs</h3>
                    <hr></hr>
                    <Form>
                        <Row>
                            <Col lg={10} md={8}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Account</Form.Label>
                                    <div >
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="select.."
                                            isSearchable={false}
                                            isDisabled={true}
                                            name="Account"
                                            defaultValue={defOption}
                                            onChange={(option) => handleAccountSelect(option)}
                                            options={accountOptions}
                                        />
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row>
                        <LoadProducts onRatePlanSelect={handleRatePlanSelect}></LoadProducts>
                        <Row >
                            <Col lg={10} md={8}>
                                <Form.Group className="mb-3 lg-2" controlId="ControlTextarea1">
                                    <div style={{ marginTop: '15px' }} >
                                        <Form.Label >SIM Numbers</Form.Label>
                                        <Form.Control ref={inputRef} as="textarea" rows={3} />
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={10} md={8}>
                                <FormGroup>
                                    <Button as="input" type="button" value="Place Order" style={{ float: 'right' }} onClick={(e) => handleSubmit(e.target)} />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Form>
                    
                   
                </Col>
            </Row>

        </>
    );
}
export default ReactivateSuspendedSIM;