import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, FormGroup, Form, Button } from 'react-bootstrap';
import { ValidateSIM, formatStatusMessage, LoadingModal, sendMessage } from '../ChangeServices/ChangeServiceCommon.js'
import Select from 'react-select';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
function SendMessage() {
    const [accountOptions, setAccountOptions] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(localStorage.getItem("PortalUserAccountName"));
    const [showModal, setShowModal] = useState(false);
    const defOption = new Option(selectedAccount, "1", true, true);
    const inputrefSIM = useRef(null);
    const inputrefMessage = useRef(null);
    const sfAccountId = localStorage.getItem('salesforceId');
    const [isInvalidMessage, setIsInvalidMessage] = useState(false);
    const [isInvalidNumber, setIsInvalidNumber] = useState(false);

    const history = useHistory();
    useEffect(() => {
        const options = new Option(selectedAccount, "1", true, true);
        const accOptions = [options]
        setAccountOptions(accOptions);
        let currURL = new URL(window.location);
        if (currURL.searchParams.has("sim")) {
            let txtSIMarea = inputrefSIM.current
            txtSIMarea.value = currURL.searchParams.get("sim");
        }
    }, [selectedAccount]);

    const handleMessageInputChange = () => {
        setIsInvalidMessage(false);
    };

    const handleNumberInputChange = () => {
        setIsInvalidNumber(false);
    };

    const handleSubmit = async (e) => {
        const txtMessagearea = inputrefMessage.current;
        const txtSIMarea = inputrefSIM.current;
        setShowModal(true);
        let resp = {};
        let enteredsims = "";
        let invalidsims = "";
        const toastProps = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        }
        try {
            if (txtMessagearea.value === "") {
                setIsInvalidMessage(true)
                throw new Error("Please enter a message.")
            }
            else if (txtSIMarea.value === "") {
                setIsInvalidNumber(true)
                throw new Error("Please enter a valid SIM Number or Phone Number.")
            }
            let formattedSimnum = String(txtSIMarea.value).replace(/\r\n\t|\n|\r|\t|\s/gm, ",");
            const simEntity = { sfAccountId: "", simNumbers: [], requiredStatus: "" };
            simEntity.sfAccountId = sfAccountId;
            enteredsims = formattedSimnum.split(",");
            if (enteredsims.length > 1) {
                throw new Error("Please enter 1 valid SIM Number or Phone Number.")
            }
            else {
                simEntity.simNumbers.push({ SIM: enteredsims[0].trim() });
            }

            simEntity.requiredStatus = "Active";
            resp = await ValidateSIM({ simEntity: simEntity, requestType: "sendmessage" })

            if (Object.hasOwn(resp, 'response') && Object.hasOwn(resp["response"], 'invalidsims')) {
                invalidsims = resp["response"]["invalidsims"];
            }
            else if (Object.hasOwn(resp, 'status') && resp["status"] === "failed") {
                throw new Error(resp["response"])
            }

            if (invalidsims !== "") {
                let invalidSimerr = new Error(invalidsims);
                invalidSimerr.name = "Invalid SIM "
                throw invalidSimerr;
            }
        }
        catch (e) {
            setShowModal(false);           
            const errorMessage = (e && e.message) || 'Please provide valid values';
            toast.error(errorMessage, toastProps);
            return false;
        }

        if (resp["status"] === "success" &&
            (Object.hasOwn(resp, 'response') && Object.hasOwn(resp["response"], 'validsims') &&
                resp["response"]["validsims"] !== "")) {
            let messageEntity = {
                "DeviceId": enteredsims[0].trim(),
                "Message": txtMessagearea.value,
                "sfAccountId": sfAccountId
            }

            try {

                let orderResp = await sendMessage({ objMessageEntity: messageEntity })

                if (Object.hasOwn(orderResp, 'payload') && orderResp["payload"]["statusType"] === "Complete") {
                    toast.success(formatStatusMessage({ objStatus: orderResp }), toastProps)
                    history.push("/home");
                }
                else {
                    toast.error(formatStatusMessage({ objStatus: orderResp }), toastProps);
                    history.push("/home");
                }
                setShowModal(false);
            }
            catch (e) {
                setShowModal(false);
                toast.error(formatStatusMessage({ objStatus: e }), toastProps);
                history.push("/home");

            }

        }
        else {
            toast.error(formatStatusMessage({ objStatus: resp }), toastProps);
            history.push("/home");
        }
        setShowModal(false);

    }
    const handleAccountSelect = (option) => {
        setSelectedAccount(option.innerHTML);
    }

    return (
        <>
            <LoadingModal showModal={showModal} >


            </LoadingModal>
            <Row>
                <Col >
                    <h3>Send Message To Device</h3>
                    <hr></hr>
                    <Form>
                        <Row>
                            <Col lg={10} md={8}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Account</Form.Label>
                                    <div >
                                        <Select
                                            className="basic-single"
                                            defaultValue={defOption}
                                            isSearchable={false}
                                            isDisabled={true}
                                            onChange={(option) => handleAccountSelect(option)}
                                            name="Account"
                                            options={accountOptions}
                                        />
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={10} md={8} >
                                <FormGroup className="mb-3">
                                    <Form.Label>SIM Number or Phone Number</Form.Label>
                                    <div>
                                        <Form.Control ref={inputrefSIM} type="text" onChange={handleNumberInputChange}
                                            style={{ border: isInvalidNumber ? '1px solid red' : '1px solid rgb(135, 163, 195)', width: '' }} />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={10} md={8}>
                                <Form.Group className="mb-3 lg-2" controlId="ControlTextarea1">
                                    <div style={{ marginTop: '15px' }} >
                                        <Form.Label >Message</Form.Label>
                                        <Form.Control ref={inputrefMessage} as="textarea" rows={3} onChange={handleMessageInputChange}
                                            style={{ border: isInvalidMessage ? '1px solid red' : '1px solid rgb(135, 163, 195)', width: '' }} />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={10} md={8}>
                                <FormGroup>
                                    <Button as="input" type="button" value="Send Message" style={{ float: 'right' }} onClick={(e) => handleSubmit(e.target)} />
                                </FormGroup>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default SendMessage;