import Form from 'react-bootstrap/Form';
import { themeColors } from "../../helpers/utils";
import "./Usage.css"
import React, { useRef } from 'react';
import FalconTooTip from "../falcon/common/ToolTip"


const UsageFilter = ({ filterOption, setFilterOption }) => {
    const filterUsage = useRef(null)
    const handleFilterSelection = (event) => {
        setFilterOption(event)
    }

    return (
        <div>
            <Form>
                <h5 className='mb-3 fw-bold' style={{ marginTop: '-10px' }}>Filter by</h5>
                <div className=" d-flex flex-row" >
                    <Form.Check
                        type='radio'
                        data-selected='false'
                        onChange={(event) => { handleFilterSelection(event) }}
                        checked={filterOption === 'All Assets'}
                        className='mr-3'
                        onClick={handleFilterSelection}
                        data-name="All Assets"
                        name="filter"
                        style={{ marginLeft: '24px' }}
                        title="All Assets" />
                    <span className="fw-bold" style={{ fontSize: '14px' }}>All Assets</span>
                </div>

                <div className=" d-flex flex-row">
                    <Form.Check
                        data-selected='false'
                        onChange={(event) => { handleFilterSelection(event) }}
                        checked={filterOption === 'Assets with Usage'}
                        type='radio'
                        className='mr-3' style={{ marginLeft: '24px' }}
                        data-name='Assets with Usage'
                        name="filter"
                        title="Assets with Usage" />
                    <span className="fw-bold" style={{ whiteSpace: 'nowrap', position: 'relative', fontSize: '14px' }}>Assets with High Usage
                        <div ref={filterUsage} style={{ width: '1.1rem', position: 'absolute', right: -16, top: -15 }} className="pulse" >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ fill: themeColors.primary }}  >
                                <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 
                            32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 
                            24-24h16v-64H224c-13.25
                            0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z" />
                            </svg>

                            <FalconTooTip target={filterUsage}>
                                <span>Value from High Usage Threshold used for this filter</span>
                            </FalconTooTip>
                        </div>
                    </span>
                </div>
                <div className=" d-flex flex-row" >
                    <Form.Check
                        data-selected='false'
                        onChange={(event) => { handleFilterSelection(event) }}
                        checked={filterOption === 'Assets with Zero'}
                        type='radio'
                        className='mr-3'
                        data-name='Assets with Zero'
                        name="filter"
                        style={{ marginLeft: '24px' }}
                        title="Assets with Zero" />
                    <span className="fw-bold" style={{ fontSize: '14px' }}>Assets with Zero Usage</span>
                </div>
            </Form>
        </div>)

}
export default UsageFilter