import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, FormGroup, FormControl } from 'react-bootstrap';
import { themeColors } from "../../helpers/utils";

const CreateEditUserInfo = ({ show, onHide, onSubmit, editData, onForgotSubmit }) => {
    const [formData, setFormData] = useState({
        FirstName: '',
        LastName: '',
        EmployeeId: '',
        EmailAddress: '',
        PhoneNumber: '',
        pkcontact: '',
        Address1: localStorage.getItem('Address1'),
        City: localStorage.getItem('City'),
        State: localStorage.getItem('State'),
        Zip: localStorage.getItem('Zip'),
        AddToIOS: true,
        IsActive: true,
        SalesForceId: localStorage.getItem('salesforceId'),
        AccountName: localStorage.getItem('AccountName'),
        SendEmail: true
    });
    const regexCheck = /^\d{10}$/;
    const formatPhoneNumber = (input) => {
        return input.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    };

    const clearForm = () => {
        setFormData({
            FirstName: '',
            LastName: '',
            EmployeeId: '',
            EmailAddress: '',
            PhoneNumber: '',
            pkcontact: '',
            Address1: localStorage.getItem('Address1'),
            City: localStorage.getItem('City'),
            State: localStorage.getItem('State'),
            Zip: localStorage.getItem('Zip'),
            AddToIOS: true,
            IsActive: true,
            SalesForceId: localStorage.getItem('salesforceId'),
            AccountName: localStorage.getItem('AccountName'),
            SendEmail: true
        });
    };

    useEffect(() => {
        if (editData) {
            setFormData({
                FirstName: editData.FirstName,
                LastName: editData.LastName,
                UserName: editData.UserName,
                EmployeeId: editData.EmployeeID,
                EmailAddress: editData.EmailAddress,
                PhoneNumber: editData.PhoneNumber,
                pkcontact: editData.pkContact,
                Address1: localStorage.getItem('Address1'),
                City: localStorage.getItem('City'),
                State: localStorage.getItem('State'),
                Zip: localStorage.getItem('Zip'),
                AddToIOS: true,
                IsActive: editData.IsActive,
                SalesForceId: localStorage.getItem('salesforceId'),
                AccountName: localStorage.getItem('AccountName'),
                SendEmail : true
            });
        } else {
            clearForm();
        }
    }, [editData]);

    const handleChange = (e) => {
        debugger;
        const { id, value, type, checked } = e.target;
        let updatedValue = value;
        if (id === "PhoneNumber") {            
            const sanitizedInput = value.replace(/[^0-9-]/g, '');
            updatedValue = sanitizedInput;
            if (regexCheck.test(sanitizedInput)) {
                updatedValue = formatPhoneNumber(sanitizedInput);
            }
        }
        setFormData({
            ...formData,
            [id]: type === 'checkbox' ? checked : updatedValue,
        });
    };

    const handleSubmit = () => {
        onSubmit(formData);
        onHide(); 
    };

    const handleClose = () => {       
        onHide();
    };   

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{editData ? 'Edit User Profile' : 'Add User Profile'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <h5 class="mb-4">Basic Information:</h5>
                      
                        <Row className="ml-1">
                            <Col className="" md={3} lg={3} xl={2} >
                                <span style={{ fontSize: "0.9rem" }} className="live-data-title">Email Address</span>
                            </Col>
                            <Col sm={12} md={6} lg={6} xl={5} className="mb-3" >
                                <FormGroup controlId="EmailAddress">
                                    <FormControl
                                        name="EmailAddress"
                                        value={formData.EmailAddress}
                                        isDisabled={true}
                                        onChange={handleChange}
                                        className="input-manage-field"
                                        maxLength={50}
                                        type="text"
                                        disabled={!!editData} 
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                                <FormGroup controlId="restpassword">
                                    <Button style={{ background: "transparent", border: "none", padding: 0, margin: 0 }} onClick={onForgotSubmit} disabled={!editData}>
                                        <span style={{ textDecoration: "underline", color: themeColors.primary, fontSize: "0.9rem" }} >Reset Password</span>
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="ml-1">
                            <Col className="" md={3} lg={3} xl={2} >
                                <span style={{ fontSize: "0.9rem" }} className="live-data-title">First Name</span>
                            </Col>
                            <Col sm={12} md={6} lg={6} xl={5} className="mb-3" >
                                <FormGroup controlId="FirstName">
                                    <FormControl
                                        name="FirstName"
                                        value={formData.FirstName}
                                        onChange={handleChange}
                                        className="input-manage-field"
                                        maxLength={50}
                                        type="text"
                                    />
                                    </FormGroup>
                            </Col>                           
                        </Row>
                        <Row className="ml-1">
                            <Col className="" md={3} lg={3} xl={2} >
                                <span style={{ fontSize: "0.9rem" }} className="live-data-title">Last Name</span>
                            </Col>
                            <Col sm={12} md={6} lg={6} xl={5} className="mb-3" >
                                <FormGroup controlId="LastName">
                                    <FormControl
                                        name="LastName"
                                        value={formData.LastName}
                                        onChange={handleChange}
                                        className="input-manage-field"
                                        maxLength={50}
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>                            
                        </Row>
                        <Row className="ml-1">
                            <Col className="" md={3} lg={3} xl={2} >
                                <span style={{ fontSize: "0.9rem" }} className="live-data-title">Employee ID</span>
                            </Col>
                            <Col sm={12} md={6} lg={6} xl={5} className="mb-3" >
                                <FormGroup controlId="EmployeeId">
                                    <FormControl
                                        name="EmployeeId"
                                        value={formData.EmployeeId}
                                        onChange={handleChange}
                                        className="input-manage-field"
                                        maxLength={50}
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>                       
                        <Row className="ml-1">
                            <Col className="" md={3} lg={3} xl={2} >
                                <span style={{ fontSize: "0.9rem" }} className="live-data-title">PhoneNumber</span>
                            </Col>
                            <Col sm={12} md={6} lg={6} xl={5} className="mb-3" >
                                <FormGroup controlId="PhoneNumber">
                                    <FormControl
                                        name="PhoneNumber"
                                        value={formData.PhoneNumber}
                                        onChange={handleChange}
                                        className="input-manage-field"
                                        maxLength={50}
                                        type="text"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="ml-1">
                            <Col className="" md={3} lg={3} xl={2} >
                                <span style={{ fontSize: "0.9rem" }} className="live-data-title">Active</span>
                            </Col>
                            <Col sm={12} md={6} lg={6} xl={5} className="mb-3 ml-3"  >
                                <FormGroup controlId="AddToIOS">
                                    <Form.Check
                                        name="AddToIOS"
                                        checked={formData.AddToIOS}
                                        onChange={handleChange}
                                        type="checkbox"
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button as="input" type="button" value={editData ? 'Update User' : 'Create User'} onClick={handleSubmit}>                       
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateEditUserInfo;