import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Modal, Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import "./SimProfile.css";
import PresentationComponent from "./SharedComponent/PresentationComponent";
import ChangeService from '../common/ContextMenu.js';
import { throwErrorIfNon200Response, themeColors, generateErrorElementWithContactInfo } from "../../helpers/utils";
import SessionHistory from './SessionHistory';

const LiveData = () => {
    const history = useHistory();
    const location = useLocation();

    
    const [isLoading, setIsLoading] = useState(false);
    const [liveData, setLiveData] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        document.title = "Advantix SmartSIM Portal - Live Data";
        fetchLiveData();
    }, []);

   

    const fetchLiveData = () => {
        const simNumber = location.pathname.split("/")[2];
        const payload = { "SIMNo": simNumber };
        setIsLoading(true);

        fetch('/api/SmartSIMInventoryController/GetLiveData', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post',
            body: JSON.stringify({ "provider": "AdvantixSmartSIM", payload })
        })
            .then(throwErrorIfNon200Response)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    const { payload } = data;
                    const { Vendor, Model, CountryName, IMEI, MCCMNC, IP, APN, ICCID, statusType, PDP, ...tempData } = payload;
                    let errCode = '', errDesc = '';
                    if ('errorCode' in tempData) {
                        errCode = tempData['errorCode'].includes('Unhandled') ? "Device Not Found" : "";
                        tempData.errorCode = errCode;
                    }

                    if ('errorDescription' in tempData) {
                        errDesc = tempData['errorDescription'].includes("Unhandled") ? "This SIM is not registering a connection to the network-please check that the SIM is properly inserted into a device and that the device is turned on" : "";
                        tempData.errorDescription = errDesc;
                    }

                    const Live_Data = {
                        "Vendor": Vendor,
                        "Model": Model,
                        "Country": CountryName,
                        "MCCMNC": MCCMNC,
                        "APN": APN,
                        "ICCID": ICCID,
                        "IMEI": IMEI,
                        "IP": IP,
                        "PDP": PDP,
                        ...tempData
                    };

                    setLiveData(Live_Data);
                } else {
                    setLiveData(null);
                }
                setIsLoading(false);
            })
            .catch(ex => {
                setLiveData(null);
                setIsLoading(false);
                toast.error(generateErrorElementWithContactInfo("Error retrieving live data."));
            });
    }

    const handleNavigation = (type) => {
        const simNumber = location.pathname.split("/")[2];
        const path = `${type}/${simNumber}`;
        history.push(path);
    }

    const handleShowModal = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    return (
        <>
            {isLoading ? (
                <div className="d-flex mt-11">
                    <Spinner className="m-auto" style={{ color: themeColors.primary }} type="grow" />
                </div>
            ) : (
                <>
                    <h4 className='text-secondary mb-2'>Inventory - Detail-Live Data</h4>
                    <>
                        {liveData ? (
                            <Row>
                                <Col>
                                    <Form>
                                        <Row>
                                            <Col md={2} sm={6} lg={2} className="mb-3">
                                                <h4 className="inventory-headline">Asset Details</h4>
                                            </Col>
                                            <Col md={7} sm={12}>
                                                <Button as="Link" onClick={() => handleNavigation('/inventorydetails-live-data')} className="active-button mr-2">
                                                    <span>Live Data</span>
                                                </Button>
                                                <Button as="Link" onClick={() => handleNavigation('/inventorydetails-inventory')} disabled={!liveData} className='not-active-button'>
                                                    <span>Inventory</span>
                                                    </Button>
                                                    <ChangeService />        
                                                </Col>
                                                
                                            <Col className="mb-4 mt-4 ml-3" md={5}>
                                                <PresentationComponent
                                                    data={liveData}
                                                    FirstColsm={5}
                                                    FirstColmd={6}
                                                    FirstCollg={5}
                                                    SecondColsm={7}
                                                    SecondColmd={7}
                                                    SecondCollg={7}
                                                    Title="Live Data:"
                                                />
                                            </Col>
                                        </Row>
                                            <Row>
                                                <Col style={{ marginLeft: '17px', marginTop: '-2%' }} sm={6} lg={3} md={6}>
                                                <Button onClick={handleShowModal} style={{ background: "transparent", border: "none", padding: 0, margin: 0 }}>
                                                    <span style={{ textDecoration: "underline", color: themeColors.primary, fontSize: "0.9rem" }}>View Session History</span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Col>
                            </Row>
                        ) : (
                            <h4 className="text-center" style={{ color: themeColors.warning }}>No Data</h4>
                        )}
                    </>
                </>
            )}
            <SessionHistory show={showModal} onHide={handleCloseModal} />
        </>
    );
}

export default LiveData;
