import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './customTooltip.css';

export default forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            getReactContainerClasses() {
                return ['custom-tooltip'];
            },
        };
    });

    return (
        <div className="custom-tooltip">
            <p>
                <span>{props?.valueFormatted || props?.value}</span>
            </p>
        </div>
    );
});
