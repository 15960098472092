import React from 'react';
import classNames from 'classnames';
import { Modal, ModalHeader } from 'reactstrap';
import ScrollBarCustom from '../falcon/common/ScrollBarCustom';
import { Link, useHistory } from "react-router-dom";
import './SidePanelModal.css';


const SidePanelModal = ({ sideModalOpen, setSideModalOpen, usageData, usagePoolGroupData}) => {
    const history = useHistory();    
    
    let paymentUrl = '';
    if (process.env.REACT_APP_UPDATE_PAYMENT_URL) {
       let pmtUrl = new URL(process.env.REACT_APP_UPDATE_PAYMENT_URL);
        pmtUrl.searchParams.set('Email', window.localStorage.getItem("PortalUserEmail"));
        paymentUrl = pmtUrl.href;
    }
    
        
    const closeSideModal = () => setSideModalOpen(false);
    const getResetPasswordToken = () => {
        fetch('/api/AuthenticateUser/GenerateResetToken', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post'
        })
            .then((response) => response.json())
            .then((data) => {                
                if (data.statusType === "Error") {
                    // let path = `/`;
                    // this.props.history.push(path);
                    // TODO on data fetch failure should there be 404 page or toast error message
                } else if (data.statusType === "Exception") {
                    // TODO on data fetch failure should there be 404 page or toast error message
                } else if (data.statusType === "ResetRequired") {
                    history.push(`/change-password?${data.resetAuthToken}`);

                }

            })
            .catch((ex) => {
                console.log(`error: ${ex}`);
            });
    };

    return (
        <Modal
            isOpen={sideModalOpen}
            toggle={closeSideModal}
            modalClassName="overflow-hidden modal-fixed-right modal-theme"
            className="modal-dialog-vertical side-modal"
            contentClassName="vh-100 border-0"
        >
            <ModalHeader tag="div" toggle={closeSideModal} className="modal-header-settings">
                <div className="py-1 flex-grow-1">
                    <h5 className="text-white">
                        My Account
                    </h5>
                </div>
            </ModalHeader>
            <ScrollBarCustom
                className="modal-body side-modal-body"
                contentProps={{
                    renderer: ({elementRef, ...restProps}) => (
                        <span
                            {...restProps}
                            ref={elementRef}
                            className={classNames('p-card position-absolute', {'border-left': false})}
                        />
                    )
                }}
            >
                <h5 className="fs-0 d-flex align-items-center">Billing Information</h5>
                <p className="fs--1">{usageData && usageData.CustomerName}</p>
                <p className="fs--1">{usagePoolGroupData && usagePoolGroupData.DataPoolMB}</p>
                <p className="fs--1">{usageData && usageData.Address1}</p>
                <p className="fs--1">{usageData && usageData.Address2}</p>
                <p className="fs--1">{usageData && usageData.City} {usageData && usageData.State}, {usageData && usageData.Zip} </p>
                <p className="fs--1"><a href={paymentUrl} target="_blank"
                                        rel="noopener noreferrer">Update Payment Method</a></p>
                <hr/>
                <h5 className="fs-0 d-flex align-items-center">Contact Advantix</h5>
                <p className="fs--1">801-308-8480</p>
                <p className="fs--1">smartsim-support@advantix.net</p>
                <hr/>
                <p className="fs--1 reset-password-link" onClick={getResetPasswordToken}>Reset Password</p>
                <p className="fs--1"><Link to="/">Logout</Link></p>
            </ScrollBarCustom>
        </Modal>
    );
};

export default SidePanelModal;
