import React, { useState, useEffect } from "react"
import { Row, Col, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import Styles from "./ActivateModal.module.css"
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { themeColors } from '../../helpers/utils';

const PendingActivationRequest = (props) => {
    const [show, setShow] = useState(false)
    const [orderID, setOrderId] = useState(null);
    const [OrderRatePlan, setOrderRatePlan] = useState([])
    const [internalID, setInternalID] = useState(null)
    const [currentSelectedPlan, setCurrentedPlan] = useState(null)
    const [currentSelectedSIMNumbers, setSIMNumbers] = useState([])

    useEffect(() => {
        const id = props.location.pathname.split("/")[2]
        const internalId = props.location.pathname.split("/")[3]
        localStorage.setItem('props', JSON.stringify(this))
        setOrderId(id)
        setInternalID(internalId)
        if (orderID) {
            fetchOrderDetails()
        }

    }, [orderID])

    const fetchOrderDetails = () => {
        fetch("api/OrderController/OrderDetails", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "post",
            body: JSON.stringify({
                "sfAccountId": localStorage.getItem('salesforceId'),
                "OrderId": orderID

            })
        }).then(response => {
            return response.json().then(data => {
                TransformData(data)
            })
        })
    }


    const TransformData = (data) => {
        const mapFormat = new Map()
        data.forEach(sim => {
            let RatePlans = []
            if (mapFormat.get(sim['PlanName'])) {
                RatePlans.push(sim)
                const currentData = mapFormat.get(sim['PlanName'])
                const currentArray = [...currentData['PlanNames'], ...RatePlans]
                mapFormat.set(sim['PlanName'], { PlanNames: currentArray })

            }
            else {
                RatePlans = []
                RatePlans.push(sim)
                mapFormat.set(sim['PlanName'], { PlanNames: RatePlans })
            }
        })

        let newArray = []
        let newObject = {}
        for (let [key, value] of mapFormat.entries()) {
            newObject = {
                Name: key,
                Rateplans: value['PlanNames']
            }
            newArray.push(newObject)
        }
        setOrderRatePlan(newArray)

    }

    const SelectCurrentPlan = (name) => {
        setShow(true)
        const found = OrderRatePlan.filter(order => order['Name'] === name)
        setCurrentedPlan(found[0])
        console.log(OrderRatePlan);
        const simNumber = OrderRatePlan
            .filter(order => order['Name'] === name) // Filter by name
            .flatMap(order => order['Rateplans'].map(ratePlan => ratePlan['ItemDescription']));
        setSIMNumbers(simNumber)
    }

    return (
        <>
            <Modal size="lg" centered show={show} onHide={() => { }}>
                <Modal.Body style={{ paddingLeft: "2rem" }}>
                    <Row className="mt-3 " style={{ alignItems: "center" }} >
                        <Row sm={2} md={6} lg={6} xl={1} xs={3}>
                        <Col sm={2} md={6} lg={6} xl={1} xs={3} className="field-root mr-1"    >
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4">Quantity</span>
                        </Col>
                        <Col sm={2} md={6} lg={6} xl={1} xs={3} className="field-root mr-2"    >
                                <span style={{ fontSize: "0.9rem", marginLeft: "70px" }} className="live-data-title mr-4">Rate Plan</span>
                            </Col>
                        </Row>
                        <Row sm={2} md={6} lg={6} xl={1} xs={3}>
                        <Col sm={2} md={4} lg={6} xl={1} xs={7} className=" manage-field-root" >
                             <span style={{ fontSize: "0.9rem", marginLeft: "25px" }} className="live-data-title mr-4">{currentSelectedPlan?.['Rateplans'].length}</span>
                        </Col>                       
                        <Col sm={2} md={4} lg={6} xl={2} xs={1} className=" manage-field-root" >
                                <span style={{ fontSize: "0.9rem", marginLeft: "75px" }} className="live-data-title mr-4">{currentSelectedPlan?.['Name']}</span>
                            </Col>
                        </Row>
                        <Col sm={2} md={2} lg={2} xl={12} className="field-root mr-1 mt-4 mb-1"    >
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4"> SIM Number(s):</span>
                        </Col>
                        <Col lg={12}>
                            <div>
                                {currentSelectedSIMNumbers.map((item, index) => (
                                    <div key={index}>{item}</div>
                                ))}
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col className="mt-4" style={{ display: "flex", marginLeft: "80%", padding: "1.5rem" }}>
                           
                            <Button variant="secondary" onClick={() => { setShow(false) }}>
                                Close
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            {OrderRatePlan.length > 0 ?
                <>
                    <Row>
                        <Col xl={3} className="mb-4">
                            <h4 className='text-secondary mb-2'>Order Details</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={2} xs={5} className="mb-4">
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title">Account Name</span>
                        </Col>
                        <Col xl={3} xs={7}>
                            <span className='text-secondary mb-2' style={{ whiteSpace: "nowrap", fontSize: "0.9rem" }}>{props?.['usageData']?.['CustomerName']}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={2} xs={5} className="mb-4">
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title">Order Number</span>
                        </Col>
                        <Col xl={3} xs={7}>
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{internalID}</span>
                        </Col>
                    </Row>


                    <Row style={{ position: "relative" }}>
                        <Col xl={1} xs={7} sm={1} className=" mb-2">
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title">Quantity</span>
                        </Col>
                        <Col xl={4} xs={12} sm={3} className="mb-2" >
                            <span style={{ fontSize: "0.9rem" }} className="live-data-title">Rate Plan</span>
                        </Col>
                        {OrderRatePlan.map(order => {
                            return (
                                <Row>
                                    <Col xl={1} xs={7} sm={1} className=" mb-2" >
                                        <span style={{ fontSize: "0.9rem", marginLeft: "5px" }} className='text-secondary mb-2'>{order['Rateplans'].length}</span>
                                    </Col>
                                    <Col xl={4} xs={12} sm={3} className="mb-2" >
                                        <p style={{ fontSize: "0.9rem", marginLeft: "5px" }} className='text-secondary mb-2'>{order['Name']}</p>
                                    </Col>
                                    <Col xl={2} xs={3} sm={2} className="mb-2">
                                        <Button data-id={order['Name']} onClick={(data) => {
                                            SelectCurrentPlan(data.target.dataset.id)

                                        }} style={{ background: "transparent", border: "none", padding: 0, margin: 0 }} className='text-secondary mb-2'>
                                            <span data-id={order['Name']} style={{ textDecoration: "underline", color: themeColors.primary, fontSize: "0.9rem" }}>View SIM Number(s)</span></Button>
                                    </Col>
                                </Row>
                            )
                        })}
                        <Row className="mt-5 mb-5">
                            <h6 className='mb-3 fw-bold' style={{ fontSize: "0.9rem" }}>Order Created By</h6>
                            <Row >
                                <Col sm={12} md={4} lg={3} xl={2}  >
                                    <span style={{ fontSize: "0.9rem" }} className="live-data-title">Name</span>
                                </Col>
                                <Col sm={12} md={6} lg={3} xl={3} className="mb-3" >
                                    <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{localStorage.getItem('PortalUserFullname')} </span>

                                </Col>
                            </Row>
                            <Row>
                                <Col className="" md={4} lg={3} xl={2}  >
                                    <span style={{ fontSize: "0.9rem" }} className="live-data-title">Email Address</span>
                                </Col>
                                <Col sm={12} md={6} lg={3} xl={3} className="mb-3" >
                                    <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{localStorage.getItem('PortalUsername')}</span>
                                </Col>
                            </Row>
                        </Row>


                        <Row style={{ alignItems: "" }}>
                            <Col xs={6} >
                                <Button
                                    onClick={() => {
                                        const history = props['history']
                                        history.goBack()
                                    }}

                                    style={{
                                        background: "transparent", border: "none", padding: 0, margin: 0, whiteSpace: "nowrap"
                                    }}><span
                                        style={{ color: themeColors.secondary }}
                                    >  <FontAwesomeIcon icon={faChevronLeft} fontSize='5x' style={{ fontSize: '1rem', marginRight: "0.4rem" }} />
                                        Return to Request</span></Button>
                            </Col>
                           
                        </Row>

                    </Row>
                </> : <span>NO data</span>

            }
        </>
        
        )




}



export default PendingActivationRequest