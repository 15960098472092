import React, { useEffect, useState } from 'react';
import { throwErrorIfNon200Response, generateErrorElementWithContactInfo } from "../../helpers/utils";
import { toast } from "react-toastify";
import { UserInfoTable } from './UserInfoTable.js';
function UserInfo() {
    const [UserInfoJson, setUserInfoJson] = useState(null);

    useEffect(() => {
        fetch('api/AuthenticateUser/GetUsers', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post',
             body: JSON.stringify({
                 sfAccountId: localStorage.getItem('salesforceId')
             })
        })
            .then(throwErrorIfNon200Response)
            .then((response) => {
                return response.json().then((data) => {
                    if (Array.isArray(data)) {
                        if (data.length > 0) {
                            setUserInfoJson(data);
                        }
                    } else {
                        throw new Error("Error retrieving User details.");
                    }
                });
            })
            .catch((ex) => {
                setUserInfoJson([]);
                toast.error(generateErrorElementWithContactInfo("Error retrieving User details."));
            });
    }, []);

    return (
        <>
            <UserInfoTable data={UserInfoJson}></UserInfoTable>
        </>
    );
}

export { UserInfo };